import React, { useState, useEffect } from "react";
import axios from "axios";
// import './LogoutButton.css';
import "../signup/Css/devloper.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { encryptData } from "../common/common";
import { authenticateConfig } from "../common/Config";
import { CookiesGet } from "../common/CookiesGet";
import auth, { getAuth } from "../../../components/services/auth";
import { Modal, Button } from "react-bootstrap";

const LOG_OUT = process.env.REACT_APP_SEND_LOG_OUT;
function LogoutButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const alert = useAlert();
  const Navigate = useNavigate();

  const handleLogout = () => {
    setShow(true);
  };

  const LogoutConformation = () => {
    try {
      axios.post(
        LOG_OUT,
        { id: encryptData(getAuth()?.uid) },
        authenticateConfig(getAuth()?.dynamicUserToken)
      );
      auth.logout();
      Navigate("/login");
      alert.success("You are now logged out.", {
        position: "top right",
        color: "red",
        timeout: 3000,
      });
      const newId = "dynamic-parent-" + "logout";
      props?.sendData(newId)
     
      // window.location.reload();
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="logoyt">
      <div className="right_side_logout mt-2">
        <div onClick={handleLogout}>
          <i className="fas fa-sign-out-alt pe-2"></i>
          <span>Log Out</span>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        id="deletepop"
        animation={true}
        className="dltpp"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="edit_siot">
            <div className="icon_ff logout">
            <i class="fal fa-times-circle"></i>
            </div>
            <div className="msg_aredf">
              <h4>Are you sure?</h4>
              <p>You want to Log Out?</p>
            </div>
            <div className="bnt_ers">
              <button className="cancle" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="cancle dlt logout"
                onClick={LogoutConformation}
              >
                Log Out
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LogoutButton;
