import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Imaginationheader from "../imaginationHeader";
import axios from "axios";
import razorpay from "../Images/razorpay_logo.png";
import enteramount from "../Images/enter-amount.png";
import coin from "../Images/coin.png";
import calendar from "../Images/calendar.png";
import useUserData from "../useUserData";

export default function AiLimitHistory(sendData) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [aiHistoryRecords, setAiHistoryRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const NavigateTools = () => {
    navigate("/tools");
  };

  const guitarUser = JSON.parse(localStorage.getItem("guitar_user"));
  const userId = guitarUser ? guitarUser.uid : null;

  const fetchAiLimitHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AI_LIMIT_HISTORY}/${userId}`
      );
      console.log(response.data.data);

      // setAiHistoryRecords(response.data.data);
      const sortedData = response.data.data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
      setAiHistoryRecords(sortedData);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching AI history records:", err);
      // setError("Failed to fetch AI history records.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAiLimitHistory();
  }, [userId]);

  const formatOccasionName = (name) => {
    return name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Logic for pagination
  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = aiHistoryRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(aiHistoryRecords.length / itemsPerPage);

  // Generate pagination array
  const generatePagination = () => {
    const pagination = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(i);
      }
    } else {
      pagination.push(1, 2);
      if (currentPage > 2 && currentPage < totalPages - 1) {
        pagination.push(currentPage);
      }
      pagination.push(totalPages - 1, totalPages);
    }
    return [...new Set(pagination)];
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  // function for checking limit of the generate image start
  const USER_DETAILS = localStorage.getItem("guitar_user");
  const parsedUserDetails = USER_DETAILS ? JSON.parse(USER_DETAILS) : null;
  useEffect(() => {
    const currentUserId = parsedUserDetails?.uid;

    if (window.location.pathname === "/ai-limit-history") {
      const storedUserId = sessionStorage.getItem("currentUserId");

      // If the user has changed or page hasn't been refreshed, reload
      if (storedUserId !== currentUserId) {
        sessionStorage.setItem("currentUserId", currentUserId);
        window.location.reload();
      }
    }
  }, []);


  return (
    <>
      <Imaginationheader />
      <div className="imagination-history-container card-history-page history-limit-page">
        <div className="cstm-container imagination-container">
          <div className="generated-images-grid">
            <div className="step2-main-heading">
              <h2>AI Limit History</h2>
              <p className="generate-btn brdr-btn" onClick={NavigateTools}>
                <i className="fas fa-chevron-left"></i> Back to tools
              </p>
            </div>

            <div className="payment-method">
              <h2>Payment Method</h2>
              <div className="payment-method-inner">
                <img src={razorpay} alt="razorpay" />
                <div className="payment-method-right">
                  <h4>{guitarUser ? guitarUser.name : ""}</h4>
                  <p>{guitarUser ? guitarUser.email : ""}</p>
                </div>
              </div>
            </div>

            <div className="payment-history">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <th>Total Deducted Credits</th>
                      <th>
                        <img src={coin} alt="coin" />
                        {Math.abs(
                          aiHistoryRecords.reduce(
                            (total, record) => total + record.credits[0].amount,
                            0
                          )
                        )}{" "}
                        Credits
                      </th>
                    </tr>
                    {currentRecords.length > 0 ? (
                        currentRecords.map((record) => (
                          <tr key={record._id}>
                            <td>
                              <div className="limit-history-info">
                                <span className="category-option">
                                  {record.occasionName.charAt(0).toUpperCase()}{" "}
                                </span>
                                <div className="limit-history-left">
                                  <h4>{formatOccasionName(record.occasionName)}</h4>
                                  <p>
                                    {new Date(
                                      record.dateAdded[0]
                                    ).toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>$ {record.credits[0].amount}</td>
                          </tr>
                        ))
                      ) : (
                      <tr>
                        <td colSpan="4" className="no-limit-history">No Limit history available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {/* pagination */}
              {totalPages > 1 && (
                <div className="pagination">
                  <button onClick={handlePrevious} disabled={currentPage === 1}>
                    &lt;
                  </button>

                  {generatePagination().map((page, index) => (
                    <button
                      key={index}
                      onClick={() => typeof page === "number" && paginate(page)}
                      className={page === currentPage ? "active" : ""}
                    >
                      {page}
                    </button>
                  ))}

                  <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    &gt;
                  </button>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
