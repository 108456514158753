import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Result_Overlay } from './commonFunction';
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from 'react-masonry-component';
import height from "./commonFunction/height";
import { nanoid } from 'nanoid';
import download from "../image/download_icon.png";
import html2canvas from 'html2canvas';

// import CropImage from "../landingpage/cropImage/cropImage";
import CropImage from "../cropImage/cropImage";
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }

const backgroundFrame = "";
const pankajtripathi = "/image/joke/anmol.jpg";


export const BirthdayDesign5 = ({ data, templateData, title, index, handleClick, clickedTabs, sendData, showTabs, message, shareImage, color, defaultImage, overlay,setDownloadCard, inlineHandleClick,pouupContent,handleMessageChange }) => {

	const messageStyle = {
		whiteSpace: 'pre-wrap',
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	}

    let uniqueId = nanoid(4);

	const [cropedImage, setCropedImage] = useState({
        isCroped: false,
        image: ""
    });


	const quoteStyle = {
		backgroundColor: `${data?.message?.background}`,
	}
	const quoteBorder = data?.message?.borderImage?.length < 10 ? `linear-gradient(90deg, ${data?.message?.borderImage}, rgb(246, 255, 106) 28%, rgb(255, 255, 255) 51%, rgb(246, 246, 102) 69%, ${data?.message?.borderImage})` : `${data?.message?.borderImage}`;
	

	let showMessage = '';
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData
	}
    const [isEditable,setIsEditable] = useState(true);
    const [imageSrc, setImageSrc] = useState(null);
	const [cropSize, setCropSize] = useState({
        width: "200",
        height: "200"
    });
	const [cropedShape, setCropedShape] = useState("rect");
	const [showCropImage, setShowCropImage] = useState(false);
	const [isEditChange,setIsEditChange] = useState(false);
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false
	});
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = () => {
        setImageLoaded(true);
    };

	const setImage = (e, size) => {
        const file = e.target.files[0];
        const el = document.getElementById("croped-el");
        if (file) {
            let imagefile = URL.createObjectURL(file);
            setImageSrc(imagefile);
            if (el) {
                const width = window.getComputedStyle(el).width;
                const height = window.getComputedStyle(el).height;
                setCropSize({
                    width: parseInt(width),
                    height: parseInt(height)
                });
                setCropedShape(size);
                setShowCropImage(true);
            }
        }
    }
	const contentLimit = (e,limit)=>{
        const text = e.target.textContent;
        const words = text.split(' ');
        setIsEditChange(true);
        // If the number of words exceeds 50, prevent further input
        if (words.length > limit) {
            // Remove the last word from the contenteditable element
            e.target.textContent = words.slice(0, limit).join(' ');
            alert.error(`Maximum word limit reached (${limit} words).`, {
                timeout: 3000,
                position: "top right"
            })
            // Prevent default behavior to stop further input
            e.preventDefault();
        }
    }
	const handleDownloadClick = async (e, index) => {
        let el = document.getElementById(`downloaded-content-${index}`);
        let downloadBtn = document.getElementById(`download-btn-${index}`);
        const nameEl = document.getElementById(`name-edit-${index}`);
        const imageEl = document.getElementsByClassName(`image-edit-${uniqueId}`)[0];   
		if(nameEl){
			nameEl.style.border="";
		}
        imageEl.style.border="";
        if (downloadBtn.innerText === "Please wait...") {
            return;
        }
        if(!isEditChange){
            const el = document.getElementById(`name-edit-${index}`);
            const imageEl = document.getElementsByClassName(`image-edit-${uniqueId}`)[0];
            alert.success("You can also edit this Booklet with your information to make it more excited for the event.",{
                timeout:4000,
                position:'top center'
            });
            if(el){
                el.style.border = "2px dotted red";
                el.classList.add("animate__animated", "animate__shakeX");
                await imageEl.classList.add("animate__animated", "animate__shakeX");
                imageEl.style.border = "3px dotted red";
                // Remove border after 3 seconds
                setTimeout(() => {
                    el.style.border = "";
                    imageEl.style.border = "";
                }, 3000);
                setTimeout(()=>{
                    setIsEditChange(true);
                },200)
                return;
            }
            
        }
        downloadBtn.innerText = "Please wait...";
        setIsEditable(false);
        setTimeout(() => {
            let elementToCapture = el;
            html2canvas(elementToCapture, {
                scale: 4, quality: 1, useCORS: true,
                // backgroundColor: 'transparent' 
            }).then((canvas) => {
                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = `varnz_card.png`;
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;
                a.click();
                downloadBtn.innerText = "Download";
                setIsEditable(true);
                
            });
        }, 100)
    };

	useEffect(() => {
        if (data.image?.backgroundFrame || backgroundFrame) {
            const img = new Image();
            img.onload = () => {
                setImageLoaded(true);
            };
            img.src = data.image?.backgroundFrame || backgroundFrame;
        }
    }, [data.image?.backgroundFrame || backgroundFrame]); // Re-run effect when imageUrl changes

	const design = (
		<>
			<div className="result_items jokeDesignOtr jokeDesign4 jokeDesign5" >
				{/* {
					!shareImage && <Result_Overlay clickedTabs={clickedTabs} index={index} downloadText={downloadText} setDownloadText={setDownloadText} captureDivRef1={captureDivRef1} title={title} sendData={sendData} showMessage={showMessage} showTabs={showTabs} handleClick={handleClick} data={data} alert={alert} design={"QuoteDesign1"} setDownloadCard={setDownloadCard} />
				} */}
				<div className="result_overlay_info">
                    <div className="inner_performing_buttons" onClick={(e) => { handleDownloadClick(e, uniqueId) }}>
                        <a style={{ cursor: 'pointer' }}>
                            <img src={download} alt="Image" loading="lazy" />
                            <p id={`download-btn-${uniqueId}`}>Download</p>
                        </a>
                    </div>
                </div>
				{
					<div id={`downloaded-content-${uniqueId}`} className='inner_result_data design_one' style={{ backgroundColor: `${data?.image?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}>
						<div className={`editor-page edit-page-bg ${pouupContent === "background_image" && 'active'}`} onClick={()=>{inlineHandleClick("background_image")}}><img src={"image/edit-page/eidt-page-bg.png"} /></div>
							{!imageLoaded && <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
								<div class="skype-loader">
									<div class="dot">
										<div class="first"></div>
									</div>
									<div class="dot"></div>
									<div class="dot"></div>
									<div class="dot"></div>
								</div>
								{/* <p>Please wait...</p> */}
							</div>}
						<div className="edit-background-frame" style={{ backgroundImage: `url(${data.image?.backgroundFrame || backgroundFrame})` }} onLoad={handleImageLoad}></div>
						<div className="result-inner" >
						 <div className="joke-result-inner" style={quoteStyle}>
						    <div className={`editor-page edit-page-text ${pouupContent === "message_edit" && 'active'}`} onClick={()=>{inlineHandleClick("message_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
							{/* <div className="joke4-image">
							   <img src={pankajtripathi} id="image-div" className="" onLoad={handleImageLoad} /> 
							</div> */}
							<div className={`birthday-special-greetings image-edit-${uniqueId}`}
								id="croped-el"
								// onClick={setImage} 
								style={{ cursor: "pointer" }}>
								<div className="greeting-book-inner-img">
									<span className="add-photo-icon"><i class="fa-light fa-image"></i> Add Photo</span>
									{
										cropedImage.isCroped &&
										<img src={cropedImage.image} alt="Image" loading="lazy" />
									}
									<input type="file" onChange={(e) => { setImage(e, "rect") }} />
								</div>
							</div>
							<h3  className="template-heading" style={{color:data?.heading?.color,fontFamily:data?.heading?.fontFamily}}> 
							<span contentEditable={isEditable} className={`gretting-edit-text  ${isEditable && 'editable'}`} onInput={(e)=>{contentLimit(e,3)}} id={`name-edit-${uniqueId}`}>
							{title === "Othersa" ? null : showMessage.heading}
							<div className={`editor-page edit-page-heading ${pouupContent === "heading_edit" && 'active'}`} onClick={()=>{inlineHandleClick("heading_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
							</span>
						 </h3>
							<p style={messageStyle} className={`gretting-edit-text template-message ${isEditable && 'editable'}`} contentEditable={isEditable} onInput={(e)=>{contentLimit(e,50)}}>{showMessage.message}</p>
				     	 </div>
				 		</div>
						<p className="branding-logo"><img src={branding} alt="branding" />Created with Varnz.ai</p>

					</div>
				}
			</div>

			<CropImage
                showCropImage={showCropImage}
                setShowCropImage={setShowCropImage}
                image={imageSrc}
                cropedImage={cropedImage}
                setCropedImage={setCropedImage}
                cropedShape={cropedShape}
                cropedSize={cropSize}
            />
		</>
	);
	return design;
}