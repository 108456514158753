import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import axios from 'axios';
import { getAuth } from '../../../../components/services/auth';
import { useSelector } from 'react-redux';
import { unauthenticateConfig } from '../../common/Config';
import coinns from "../../Invitation/images/buyvredit.png";

export const SimplePaymentModal = ({ show1, handleCloseHandel, toolId }) => {

    const PAYMENT_GETKEY = process.env.REACT_APP_GET_PAYMENT_GETKEY;
    const PAYMENT_CHECKOUT = process.env.REACT_APP_GET_PAYMENT_CHECKOUT;
    const REACT_APP_GET_PAYMENT_VERIFICATION = "https://varnz-backend.webgarh.net/api/tool-payment/verify-payment";
    // const REACT_APP_GET_PAYMENT_VERIFICATION = process.env.REACT_APP_GET_PAYMENT_VERIFICATION;

    const [amount, setAmount] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailStore, setEmailStore] = useState('');
    const [selectedAmounterror, setSelectedAmounterror] = useState("");
    const [name, setName] = useState("");
    const [calculatedValue, setCalculatedValue] = useState(null);

    const handleAmountChange = (e) => {
        const value = e.target.value;

        // Check if the value is negative
        if (value < 0) {
            setError('Negative values are not allowed');
            setCalculatedValue(null); 
            setAmount(0); 
            return;
        }


        setAmount(value); // Update amount state
      
        // Check if the value is a decimal
        if (value.includes('.')) {
          setError('Decimal values are not allowed'); // Set error message
          setCalculatedValue(null); // Clear calculated value if there's an error
        } else if (!isNaN(value) && value !== '') {
          setError(''); // Clear error if valid
          setCalculatedValue(value * 5); // Multiply amount by 16
        } else {
          setError(''); // Clear error for other invalid inputs
          setCalculatedValue(null); // Clear if input is invalid
        }
      };
      
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (error || amount === '') {
            setError('Decimal values are not allowed');
            return; 
          }
      
        setSelectedAmounterror("");

        // Prepare data to send
        const dataSend = {
            uid: getAuth()?.uid,
            amount: Number(amount),
            credit: calculatedValue,
            email: getAuth()?.email,
            currency: "USD",
        };

        try {
            const {
                data: { key },
            } = await axios.get(PAYMENT_GETKEY);

            const {
                data: { order },
            } = await axios.post(PAYMENT_CHECKOUT, dataSend);
            // Razorpay options
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const options = {
                key,
                amount: Number(amount) * 100,
                currency: "USD",
                name: "VARNZ",
                description: "Payment for services",
                image: "https://varnz.ai/logo192.png",
                order_id: order.id,
                callback_url: REACT_APP_GET_PAYMENT_VERIFICATION,
                prefill: {
                    name: userInfo?.data.name,
                    email: getAuth()?.email,
                },
                notes: {
                    address: "Razorpay Corporate Office",
                    userId: getAuth()?.uid

                },
                theme: {
                    color: "#121212",
                },
            };
            console.log(options, 'options');

            // Initialize Razorpay
            const razor = new window.Razorpay(options);
            razor.open();
        } catch (error) {
            console.error("Payment error:", error);
            setSelectedAmounterror("An error occurred while processing your payment. Please try again.");
        } finally {
            handleCloseHandel(false);
        }
    };


    return (

        <Modal
            show={show1}
            onHide={handleCloseHandel}
            backdrop="static"
            keyboard={false}
            id="deletepop"
            animation={true}
            className="dltpp payment buycredit set credit-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="freemsg" closeButton>
                <div className="left_hed_sid">
                    <img src={coinns} alt="" />
                </div>
                <div className="right_hed_sid">
                    <p>Buy Credits</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='creadit-text'>
                    <p>After you make your payment, you will receive <strong>5 times the amount you pay</strong> in credits to create cards from your imagined image.
                    <br></br><br></br>
                    Each credit allows you to generate one image with card. Please enter the amount you would like to pay to see how many credits you will receive!</p>
                </div>
                <div className="edit_siot">
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="form-group enter-amount-calculation">
                                <input
                                    type="number"
                                    id="amount"
                                    className="form-control"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    placeholder="Enter amount"
                                    min="0"
                                    required
                                />
                              <div className='credit-demo-text'>$1 = 5 Credits</div>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                {calculatedValue !== null && (
                                    <p className='you-will'>You will get {calculatedValue} credits</p>
                                )}
                            </div>
                            <div className={` m-auto':null}`}>
                                <div className="token_area_show">
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {loading ? 'Processing...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>

        // <Modal
        //     show={show1}
        //     onHide={handleCloseHandel}
        //     backdrop="static"
        //     keyboard={false}
        //     centered
        // >
        //     <Modal.Header closeButton>
        //         <Modal.Title>Enter Amount</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //         <p>
        //             After you make your payment, you will receive <strong>16 times the amount you pay</strong> in credits to create images.
        //             Each credit allows you to generate one image. Please enter the amount you would like to pay to see how many credits you will receive!
        //         </p>
        //         <form onSubmit={handleSubmit}>
        //             <div className="form-group">
        //                 <label htmlFor="amount">Amount</label>
        //                 <input
        //                     type="number"
        //                     id="amount"
        //                     className="form-control"
        //                     value={amount}
        //                     onChange={handleAmountChange}
        //                     placeholder="Enter amount"
        //                     required
        //                 />
        //                 {error && <p style={{ color: 'red' }}>{error}</p>}
        //             </div>
        //             <button type="submit" className="btn btn-primary" disabled={loading}>
        //                 {loading ? 'Processing...' : 'Submit'}
        //             </button>
        //         </form>
        //     </Modal.Body>
        // </Modal>
    );
};