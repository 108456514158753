import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Imaginationheader from "../imaginationHeader";
import axios from "axios";

export default function ImaginationHistory(sendData) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [aiHistoryRecords, setAiHistoryRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const NavigateTools = () => {
    navigate("/tools");
  };

  const guitarUser = JSON.parse(localStorage.getItem("guitar_user"));
  const userId = guitarUser ? guitarUser.uid : null;

  const fetchAiHistory = async () => {
    try {
      const response = await axios.get(
        `https://varnz-backend.webgarh.net/api/ai-history/${userId}`
      );
      console.log(response.data.data);

      // setAiHistoryRecords(response.data.data);
      const sortedData = response.data.data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
      setAiHistoryRecords(sortedData);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching AI history records:", err);
      // setError("Failed to fetch AI history records.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAiHistory();
  }, [userId]);

  // Logic for pagination
  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = aiHistoryRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(aiHistoryRecords.length / itemsPerPage);

  // Generate pagination array
  const generatePagination = () => {
    const pagination = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(i);
      }
    } else {
      pagination.push(1, 2);
      if (currentPage > 2 && currentPage < totalPages - 1) {
        pagination.push(currentPage);
      }
      pagination.push(totalPages - 1, totalPages);
    }
    return [...new Set(pagination)];
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  // function for checking limit of the generate image start
  const USER_DETAILS = localStorage.getItem("guitar_user");
  const parsedUserDetails = USER_DETAILS ? JSON.parse(USER_DETAILS) : null;
  useEffect(() => {
    const currentUserId = parsedUserDetails?.uid;

    if (window.location.pathname === "/history") {
      const storedUserId = sessionStorage.getItem("currentUserId");

      // If the user has changed or page hasn't been refreshed, reload
      if (storedUserId !== currentUserId) {
        sessionStorage.setItem("currentUserId", currentUserId);
        window.location.reload();
      }
    }
  }, []);

  return (
    <>
      <Imaginationheader />
      <div className="imagination-history-container card-history-page">
        <div className="cstm-container imagination-container">
          <div className="generated-images-grid">
            <div className="step2-main-heading">
              <h2>Your Generated Images</h2>
              <p className="generate-btn brdr-btn" onClick={NavigateTools}>
                <i className="fas fa-chevron-left"></i> Back to tools
              </p>
            </div>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}

            {aiHistoryRecords.length > 0 ? (
              <div className="images-list">
                {currentRecords.map((record) => (
                  <div key={record._id} className="image-item">
                    <div className="image-container">
                      {record.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Generated ${index + 1}`}
                        />
                      ))}
                    </div>
                    <ul className="card-history-listing">
                      <li>
                        <h2>Tools Used:</h2>
                        {record.toolName.map((tool, index) => (
                          <span key={index}>
                            {tool
                            .split('_')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                            }
                          </span>
                        ))}
                      </li>
                      <li>
                        <h2>Occasions:</h2>
                        {record.occasionName.map((occasion, index) => (
                          <span key={index}>
                            {occasion
                              .split('_') 
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                              .join(' ')} 
                          </span>
                        ))}

                      </li>
                      <li>
                        <h2>Dates Added:</h2>
                        {record.dateAdded.map((date, index) => (
                          <span key={index}>
                            {new Date(date).toLocaleString()}
                          </span>
                        ))}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              !loading && <p>No records found.</p>
            )}

            {/* pagination */}
            {totalPages > 1 && (
              <div className="pagination">
                <button onClick={handlePrevious} disabled={currentPage === 1}>
                  &lt;
                </button>

                {generatePagination().map((page, index) => (
                  <button
                    key={index}
                    onClick={() => typeof page === "number" && paginate(page)}
                    className={page === currentPage ? "active" : ""}
                  >
                    {page}
                  </button>
                ))}

                <button onClick={handleNext} disabled={currentPage === totalPages}>
                  &gt;
                </button>
              </div>
            )}
          </div>

        </div>
      </div>
    </>
  );
}