import { getAuth } from "../../../../../../../components/services/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { multiformRequest } from "./multiform.action";
import { multiformRequest } from "../../../../multiform.action";
import { nanoid } from 'nanoid';


export const savedTemplate = async ({e,formData,designType,category,occasion,promptResponse,corporateUserInfo,showTemplateTitle,sendData,prompt,setSaveImage,saveImage,alert,persoanlInfo,backgroundImage,onlineImage,title_style,message_style,other_style,isSavedEdit,allFormData})=>{
    if (saveImage.disabled) {
        return;
    }
    setSaveImage({
        text: "Please wait..",
        disabled: true
    })
    const url = process.env.REACT_APP_CORPORATE_TEMPLATE;
    // return;
    let templateData = {};
    if(isSavedEdit){
        allFormData.designType = designType;
        allFormData.category = category;
        allFormData.occasion = occasion;
        allFormData.response = promptResponse;
        allFormData.title = showTemplateTitle;
        allFormData.prompt = prompt;
        allFormData.cuid = corporateUserInfo._id
        allFormData.persoanlInfo = persoanlInfo;
        allFormData.title_style = title_style;
        allFormData.message_style = message_style;
        allFormData.other_style = other_style;
        if(backgroundImage){
            allFormData["background_image"] = onlineImage
        }
        delete allFormData.createdAt;
        delete allFormData._id;
        delete allFormData.updatedAt;
    }else{
        templateData.designType = designType;
        templateData.category = category;
        templateData.occasion = occasion;
        templateData.response = promptResponse;
        templateData.title = showTemplateTitle;
        templateData.prompt = prompt;
        templateData.cuid = corporateUserInfo._id
        templateData.persoanlInfo = persoanlInfo;
        templateData.title_style = title_style;
        templateData.message_style = message_style;
        templateData.other_style = other_style;
        if(backgroundImage){
            templateData["background_image"] = onlineImage
        }
    
        Object.keys(formData).forEach((key) => {
            // if(backgroundImage && key === "image"){
            //     templateData["profile_pic"] = onlineImage
            // }else{
                templateData[key] = formData[key]
            // }
        });
    }
    
    // console.log(allFormData,"yes you can do it")
    // return;
    
    try{
        const config = {
            headers: {
              "x-auth-token": getAuth()?.dynamicUserToken,
              "Content-Type": "multipart/form-data",
            },
        };
        const formData = isSavedEdit ? allFormData : templateData;
        const response = await axios.post(url, formData, config);
        alert.success("Saved Successfully!", {
            timeout: 3000
        });
        console.log(response);
        
    }catch(err){

    }finally{
        setSaveImage({
            text: "Saved",
            disabled: true
        });
    }
    // console.log(formData,"JJJJJJJJJJJ",templateData)

}

export const editTemplate = async ({ e, formData, designType, category, occasion, promptResponse, corporateUserInfo, showTemplateTitle, sendData, prompt, setSaveImage, saveImage, alert , persoanlInfo,setEditData,dispatch,Navigator,setMasonaryValue,isSavedTemplate,allFormData,title_style,message_style,other_style})=>{
    // const navigateor = useNavigate();
    const allData = {
        category: category,
        occasion: occasion,
        formData: formData,
        designType: designType,
        promptResponse: promptResponse,
        corporateUserInfo: corporateUserInfo,
        showTemplateTitle: showTemplateTitle,
        prompt: prompt,
        persoanlInfo: persoanlInfo,
        setMasonaryValue:setMasonaryValue,
        isSavedTemplate:isSavedTemplate,
        allFormData: allFormData,
        title_style: title_style,
        message_style: message_style,
        other_style: other_style
    }

    // console.log(allData,"ALLLDDDADADDA");
    // return;
    // localStorage.setItem("editTemplate",JSON.stringify(allData));
    // console.log(dispatch,"RRRRRRRRRRRRRRRRRRRR111")
    // setEditData("qwqwqwqwq");
    dispatch(multiformRequest(allData))
    // useNavigate("/choose-layout")
    // const newTab = window.open(`/choose-layout`, '_blank');
    // if (newTab) {
    //     newTab.focus(); // Focus on the new tab if it was successfully opened
    // }
    // return;
    // Navigator("/choose-layouts");
    Navigator("/template-edit");

}

export const Footer = ({index,persoanlInfo,setPersonalInfo,setMasonaryValue,downloadText,handleDownloadClick,setDownloadText,captureDivRef1,showTemplateTitle,sendData,alert,savedTemplate,isSavedTemplate,saveImage,formData,designType,category,occasion,promptResponse,corporateUserInfo,prompt,setSaveImage,backgroundImage,onlineImage,title_style,message_style,other_style,isSavedEdit,allFormData,save,isEditPage,editTemplate,Navigator,editIcon,download,setEditData,dispatch,scale,showCompanyInfoBtn})=>{
    let text = nanoid(4);
    return <div className="template-dwnld" >
            <div className={`personal-ifno-btn  ${showCompanyInfoBtn === false && 'd-none'}`}>
              <h3>Company Information</h3>
              <div className="on-off-btn">
                <input type="checkbox" id={`custom-switch-${index}${text}`} checked={persoanlInfo} 
                onClick={() => { 
                    setPersonalInfo(!persoanlInfo); 
                    setMasonaryValue(true) 
                    console.log(persoanlInfo,"clicked",text)
                    localStorage.setItem("company_info",!persoanlInfo)
                }} 
                />
                <label className="on-off-btn-inr" for={`custom-switch-${index}${text}`}>
                  <span className="on-btn">ON</span>
                  <span className="off-btn">OFF</span>
                </label>
              </div>
            </div>
            {
               <div className="inner_performing_buttons" >
                    <a style={{ cursor: downloadText.disabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" onClick={(e) => handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, showTemplateTitle, sendData, alert, false,scale)}>
                        <img src={download} alt="Image" />
                        <p >{downloadText.text}</p>
                    </a>
                    {
                        !isSavedTemplate && <a style={{ cursor: saveImage.disabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" onClick={(e) => savedTemplate({ e, formData, designType, category, occasion, promptResponse, corporateUserInfo, showTemplateTitle, sendData, prompt, setSaveImage, saveImage, alert, persoanlInfo, backgroundImage, onlineImage,title_style,message_style,other_style,isSavedEdit,allFormData })}>
                            <img src={save} alt="Image" />
                            <p >{saveImage.text}</p>
                        </a>
                    }
                    {
                        !isEditPage &&  <a href="javascript:void(0);" onClick={(e) => editTemplate({ e, formData, designType, category, occasion, promptResponse, corporateUserInfo, showTemplateTitle, sendData, prompt, setSaveImage, saveImage, alert, persoanlInfo, setEditData, dispatch, multiformRequest, Navigator, setMasonaryValue,isSavedTemplate,allFormData,title_style,message_style,other_style })}>
                            <img src={editIcon} alt="Image" />
                            <p >Edit</p>
                        </a>
                    }
                </div>
            }
          </div>
}

export const SocialLink = ({
    otherColor,
    otherFontFamily,
    fontStyle,
    corporateUserInfo,
    textAlignment,
    fontSize,
    className

})=>{
    return <ul className={`${className}`}>
    
    {/* {
        corporateUserInfo.company_address && <li style={{justifyContent:`${textAlignment?.otherTextAlign.textAlign}`}}>
        <i class="address-icon"></i>
        <p style={{color:`${otherColor}`,fontFamily:`${otherFontFamily}`,fontSize:`${fontSize?.otherFontSize}`,fontWeight:`${fontStyle?.otherFontStyle.bold.fontWeight}`,fontStyle:`${fontStyle?.otherFontStyle.italic.fontStyle}`,textDecoration:`${fontStyle?.otherFontStyle.underline.textDecoration}`,textAlign:`${textAlignment?.otherTextAlign.textAlign}`}}>{corporateUserInfo.company_address}</p>
    </li>
    } */}
    {
        <li style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
        <i class="fas fa-link"></i>
        <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.website_url}`}>{corporateUserInfo.website_url}</a>
      </li>
    }
    {/* {
        corporateUserInfo?.website_url && <li style={{justifyContent:`${textAlignment?.otherTextAlign.textAlign}`}}>
        <i class="link-icon"></i>
        <a style={{color:`${otherColor}`,fontFamily:`${otherFontFamily}`,fontSize:`${fontSize?.otherFontSize}`,fontWeight:`${fontStyle?.otherFontStyle.bold.fontWeight}`,fontStyle:`${fontStyle?.otherFontStyle.italic.fontStyle}`,textDecoration:`${fontStyle?.otherFontStyle.underline.textDecoration}`,textAlign:`${textAlignment?.otherTextAlign.textAlign}`}} target="_blank" href={`${corporateUserInfo.website_url}`}>{corporateUserInfo.website_url}</a>
    </li>
    } */}
    {
        corporateUserInfo?.facebook_link && <li className="social-temp-link facebook-" style={{justifyContent:`${textAlignment?.otherTextAlign.textAlign}`}}>
        <i class="fa-brands fa-facebook-f"></i>
        <a style={{color:`${otherColor}`,fontFamily:`${otherFontFamily}`,fontSize:`${fontSize?.otherFontSize}`,fontWeight:`${fontStyle?.otherFontStyle.bold.fontWeight}`,fontStyle:`${fontStyle?.otherFontStyle.italic.fontStyle}`,textDecoration:`${fontStyle?.otherFontStyle.underline.textDecoration}`,textAlign:`${textAlignment?.otherTextAlign.textAlign}`}} target="_blank" href={`${corporateUserInfo.facebook_link}`}>{corporateUserInfo.facebook_link}</a>
    </li>
    }
    {
        corporateUserInfo?.instagram_link && <li className="social-temp-link instagram-" style={{justifyContent:`${textAlignment?.otherTextAlign.textAlign}`}}>
        <i class="fa-brands fa-instagram"></i>
        <a style={{color:`${otherColor}`,fontFamily:`${otherFontFamily}`,fontSize:`${fontSize?.otherFontSize}`,fontWeight:`${fontStyle?.otherFontStyle.bold.fontWeight}`,fontStyle:`${fontStyle?.otherFontStyle.italic.fontStyle}`,textDecoration:`${fontStyle?.otherFontStyle.underline.textDecoration}`,textAlign:`${textAlignment?.otherTextAlign.textAlign}`}} target="_blank" href={`${corporateUserInfo.instagram_link}`}>{corporateUserInfo.instagram_link}</a>
    </li>
    }
    {
        corporateUserInfo?.twitter_link && <li className="social-temp-link twitter-" style={{justifyContent:`${textAlignment?.otherTextAlign.textAlign}`}}>
        <i class="fa-brands fa-twitter"></i>
        <a style={{color:`${otherColor}`,fontFamily:`${otherFontFamily}`,fontSize:`${fontSize?.otherFontSize}`,fontWeight:`${fontStyle?.otherFontStyle.bold.fontWeight}`,fontStyle:`${fontStyle?.otherFontStyle.italic.fontStyle}`,textDecoration:`${fontStyle?.otherFontStyle.underline.textDecoration}`,textAlign:`${textAlignment?.otherTextAlign.textAlign}`}} target="_blank" href={`${corporateUserInfo.twitter_link}`}>{corporateUserInfo?.twitter_link}</a>
    </li>
    }
    
    
    
</ul>
}