import React, { useEffect } from 'react'
import branding from "../multiStepForm/image/branding.png";
import LogoutButton from '../signup/Logout';
import { Link, useNavigate } from 'react-router-dom';
import AvatarWithLetter from '../../../commonComponents/avtar';
import { useState } from 'react';
import "./css/imagination.css"
import { SimplePaymentModal } from './PaymentModal/PaymentModal';

export default function Imaginationheader({ corporateUser, setAlertMessage, selectedTool }) {
  const userData = JSON.parse(localStorage.getItem("guitar_user"));

  const uid = userData.uid;
  const token = localStorage.getItem("Exapirtoken")

  const userEmail = JSON.parse(localStorage.getItem("guitar_user"));

  const [activeAI, setActiveAI] = useState(false);
  const [error, setError] = useState("");

  const [show1, setShow1] = useState(false);
  const handleCloseHandel = () => setShow1(false);

  const Navigator = useNavigate();
  const handleClick = (data) => {
    if (corporateUser >= 0) {
      setAlertMessage("")
      if (corporateUser > 0) {

        Navigator(`/${data}`);
      } else {
        setTimeout(() => {
          setAlertMessage("In order to begin crafting greetings for your team members, kindly provide the necessary business information.")
        }, 100)
      }
    } else {
      Navigator(`/${data}`);
    }
  }
  const navigate = useNavigate();
  const NavigateHistory = () => {
    navigate('/history', { state: { userDetails }});
  }
  const BNavigateHistory = () => {
    navigate('/template-history', { state: { userDetails }});
  }

  const PaymentHistory = () => {
    navigate('/ai-payment-history', { state: { userDetails }});
  }
  const LimitHistory = () => {
    navigate('/ai-limit-history', { state: { userDetails }});
  }
  const Edit = () => {
    navigate('/ai-edit', { state: { userDetails }});
  }

  const BEdit = () => {
    navigate('/settings', { state: { userDetails }});
  }

  const NavigateTool = () => {
    navigate('/tools');
  }

  const [userDetails, setUserDetails] = useState("");
  // console.log(userDetails, 'userDetails');

  const getUserData = async () => {
    try {
      const response = await fetch(`https://varnz-backend.webgarh.net/api/users/getUser/${uid}`, {
        method: 'GET',
        headers: {
          'x-auth-token': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      if (data.status === 1) {
        setUserDetails(data.data);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError
        (error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, [userData]);

  return (
    <>
      <div className='business-header imagination-new-header'>
        <div className='cstm-container'>
          <div className='busnes-header-inner'>
            <a href="/" className="business-logo"><img src={branding} alt="varnz logo" /></a>
            {/* <div className="middle_side_logo new_itr">
                <div>
                  <div class="tool-header-btns">
                    <a href="/settings" class="blue-btn cstm-btn"><i class="far fa-repeat-alt"></i>Switch to Business</a> 
                  </div>
                </div>
              </div> */}
            <div className="new-header-btns">
              <a href="/tools" className="brder-btn upgrade-btn go-to-tool-btn"><i class="fas fa-filter"></i>Explore Tools</a>
              <div className="upgrade-otr-btn">
                <a
                  href="#"
                  className="brder-btn upgrade-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow1(true);
                  }}
                ><i class="fas fa-crown"></i>Upgrade</a>
                <p
                  href="#"
                  className=" credits-btn"><i class="fas fa-coins"></i>{userDetails ? userDetails.ai_limit : '0'} Credits</p>
              </div>
              <div className='business-header-user'>
                <div className='business-acount'
                  onClick={() => { setActiveAI(!activeAI) }}
                ><i class="fa-regular fa-user"></i>My Account<i class="far fa-angle-down"></i></div>
                <div className={`business-acount-dropdown ${activeAI && 'active-ai-page'}`}>
                  <div className='user-login-info'>
                    {
                      userDetails ? (
                        userDetails.image_path ? (
                          <img src={`${process.env.REACT_APP_URL_LOCAL}/${userDetails.image_path}`} alt="User Profile" />
                        ) : (
                          <img src="https://varnz-apps-frontend.webgarh.net/image/imaginationImage/user_profile.png" alt="Default Avatar" />
                        )
                      ) : (
                        <AvatarWithLetter name={userDetails?.email || 'Unknown'} imageUrl="path/to/custom-image.jpg" />
                      )
                    }

                    <div className='user-login-info-right'>
                      <h4>{userDetails ? userDetails.name : ""}</h4>
                      <p>{userDetails ? userDetails.email : ""}</p>
                    </div>
                  </div>
                  <ul className='user-dropdown'>
                    <p className=" credits-btn mobile-credit mob-non" onClick={NavigateTool}><i class="fas fa-filter"></i> Explore Tools</p>
                    <p href="#" className=" credits-btn mobile-credit mob-non"><i class="fas fa-coins"></i>{userDetails.ai_limit} Credits</p>
                    <h3 class="submenu-heading"><span>Edit</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={Edit}><i class="far fa-user-edit"></i> Edit Personal Information</p>
                    <p className=" credits-btn mobile-credit" onClick={BEdit}><i class="far fa-user-edit"></i> Edit Business Information</p>
                    <h3 class="submenu-heading"><span>Payment History</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={PaymentHistory}><i class="far fa-money-bill-alt"></i> Payment History</p>
                    <p className=" credits-btn mobile-credit" onClick={LimitHistory}><i class="far fa-list-alt"></i> Limit History</p>
                    <h3 class="submenu-heading"><span>Cards History</span></h3>
                    <p className=" credits-btn mobile-credit" onClick={NavigateHistory}><i class="fas fa-history"></i> AI image History</p>
                    <p className=" credits-btn mobile-credit" onClick={BNavigateHistory}><i class="fas fa-history"></i> Business Saved Card History</p>
                    <li><LogoutButton /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SimplePaymentModal
        show1={show1}
        handleCloseHandel={handleCloseHandel}
        toolId={selectedTool}
      />
    </>
  )
}
