import React, { useEffect,useRef } from 'react';
import Footer from "../footer";
import Masonry from 'react-masonry-component';
import { useNavigate } from 'react-router-dom';
import chooseheading from "./images/choose-heading.png";
import { useAlert } from "react-alert";
import elemnt1 from "../../landingpage/image/sarcastic.png";
import elemnt2 from "../../landingpage/image/poem.png";
import elemnt3 from "../../landingpage/image/chat.png";
import elemnt4 from "../../landingpage/image/joke.png";
import elemnt5 from "../../landingpage/image/quote.png";
import choosecolor2 from "../../landingpage/image/choose-color-animate2.gif";
import { SketchPicker } from 'react-color';
import { useState } from "react";
import 'animate.css';
import axios from "axios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Carousel from "react-multi-carousel";
import {
  useSelector
} from "react-redux";
import ReactPaginate from 'react-paginate';
import { allTemplates } from '../../multiStepForm/template/index';
import design1Template from "../image/business-temp1.jpg";
import design2Template from "../image/business-temp2.jpg";
import design3Template from "../image/business-temp3.jpg";
import design4Template from "../image/business-temp4.jpg";
import design5Template from "../image/business-temp5.jpg";
import design8Template from "../image/business-temp8.jpg";
import design9Template from "../image/business-temp9.jpg";
import design12Template from "../image/template12.png";
import design7Template from "../image/business-temp8.jpg";
import design14Template from "../image/template14.png";
import design16Template from "../image/template16-bg.jpg";
import design15Template from "../image/template15-bg.jpg";


import FontFamily from "../fontFamily.json";
import Businessheader from '../../header/businessheader';


export const ChooseLayout = ({ editData }) => {

  const { MultiformReducer } = useSelector(response => response);
  if (!MultiformReducer.data) {
    window.location = "/multiform"
  }
  let design = localStorage.getItem("design");

  const [showElement, setShowElement] = useState({
    layout: "",
    template: "d-none",
    color: "d-none"
  });
const abc = useRef();
useEffect(()=>{
  if(abc.current){
    abc.current.scrollIntoView({ behavior: "smooth" });
  }
},[])


  const showColorPatch = [
    "SecondDesign"
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };
  const [downloadText, setDownloadText] = useState({
    text: "Download",
    disabled: false,
  });
  const [showSelected, setShowSelected] = useState(MultiformReducer.data ? MultiformReducer.data.designType : design);
  const alert = useAlert();
  const color = "orange";
  const defaultImage = "varnzTemplates/wish_someone/birthday/17.jpg"

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [searchValue, setSearchValue] = useState("");
  const [chooseDesign, setChooseDesign] = useState(MultiformReducer.data ? MultiformReducer.data.designType : design);
  const [imageResponse, setImageResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const showText = ["texture", "abstract background", "seamless pattern", "concrete texture", "background pattern", "painting"]
  const fontSizesArray = [
    "8px", "10px", "12px", "14px", "16px", "18px", "20px", "22px", "24px", "26px",
    "28px", "30px", "32px", "34px", "36px", "38px", "40px", "42px", "44px", "46px",
    "48px", "50px"
  ];
  const defaultTitleCss = {
    design1: {
      fontFamily: "Cairo-VariableFont",
      fontSize: "1.6vw",
      textAlignment: "left",
      color: "#00114B"
    },
    design2: {
      fontFamily: "Akbaal",
      fontSize: "1.4vw",
      textAlignment: "left",
      color: "#4c3630"
    },
    design3: {
      fontFamily: "'DaddyRewind', sans-serif",
      fontSize: "1.7vw",
      textAlignment: "center",
      color: "#fe8a02"
    },
    design4: {
      fontFamily: "Akbaal",
      fontSize: "1.2vw",
      textAlignment: "center",
      color: "#fe8a02"
    },
    design5: {
      fontFamily: "Bree Serif",
      fontSize: "1.2vw",
      textAlignment: "center",
      color: "#bd5614"
    },
    design6: {
      fontFamily: "Cairo-VariableFont",
      fontSize: "1.3vw",
      textAlignment: "center",
      color: "#fff"
    },
    design7: {
      fontFamily: "Montserrat",
      fontSize: "2vw",
      textAlignment: "center",
      color: "#a77e54"
    },
    design8: {
      fontFamily: "Charm-Bold",
      fontSize: "2rem",
      textAlignment: "center",
      color: "#fff"
    },
    design9: {
      fontFamily: "Libre Baskerville",
      fontSize: "26px",
      textAlignment: "center",
      color: "#792976"
    },
    design11: {
      fontFamily: "Libre Baskerville",
      fontSize: "26px",
      textAlignment: "center",
      color: "#792976"
    },
    design12: {
      fontFamily: "Libre Baskerville",
      fontSize: "26px",
      textAlignment: "center",
      color: "#792976"
    },
    design13: {
      fontFamily: "Old Standard TT",
      fontSize: "50px",
      textAlignment: "center",
      color: "#0b5e98"
    },
    design14: {
      fontFamily: "BrittanySignature",
      fontSize: "26px",
      textAlignment: "center",
      color: "#966245"
    },
    design15: {
      fontFamily: "SuezOne-Regular",
      fontSize: "42px",
      textAlignment: "center",
      color: "#d8aa7f"
    },
    design16: {
      fontFamily: "RozhaOne-Regular",
      fontSize: "42px",
      textAlignment: "center",
      color: "#b36e5d"
    },
    design17: {
      fontFamily: "CherrySwash-Regular",
      fontSize: "42px",
      textAlignment: "center",
      color: "#8d3f6d"
    },
    design18: {
      fontFamily: "Overlock-Italic",
      fontSize: "42px",
      textAlignment: "center",
      color: "#86007d",
      fontWeight:"900"
    }
  }
  const defaultMessageCss = {
    design1: {
      fontFamily: "Charm-Bold",
      fontSize: "1vw",
      textAlignment: "left",
      color: "#00114B"
    },
    design2: {
      fontFamily: "sans-serif",
      fontSize: "0.7vw",
      textAlignment: "left",
      color: "#222222"
    },
    design3: {
      fontFamily: "Montserrat",
      fontSize: "0.7vw",
      textAlignment: "left",
      color: "#222222"
    },
    design4: {
      fontFamily: "Montserrat",
      fontSize: "0.7vw",
      textAlignment: "center",
      color: "#222222"
    },
    design5: {
      fontFamily: "Bree Serif",
      fontSize: "0.7vw",
      textAlignment: "center",
      color: "#bd5614"
    },
    design6: {
      fontFamily: "Nunito Sans",
      fontSize: "0.7vw",
      textAlignment: "center",
      color: "#fff"
    },
    design7: {
      fontFamily: "Charm-Bold",
      fontSize: "18px",
      textAlignment: "center",
      color: "#00114B"
    },
    design8: {
      fontFamily: "Open Sans",
      fontSize: "13px",
      textAlignment: "center",
      color: "#fff"
    },
    design9: {
      fontFamily: "Open Sans",
      fontSize: "13px",
      textAlignment: "center",
      color: "#792976"
    },
    design11: {
      fontFamily: "Open Sans",
      fontSize: "13px",
      textAlignment: "center",
      color: "#792976"
    },
    design12: {
      fontFamily: "Open Sans",
      fontSize: "13px",
      textAlignment: "center",
      color: "#792976"
    },
    design13: {
      fontFamily: "DM Sans",
      fontSize: "13px",
      textAlignment: "left",
      color: "#0b5e98"
    },
    design14: {
      fontFamily: "Open Sans",
      fontSize: "13px",
      textAlignment: "center",
      color: "#966245"
    },
    design15: {
      fontFamily: "SuezOne-Regular",
      fontSize: "20px",
      textAlignment: "center",
      color: "#cba882"
    },
    design16: {
      fontFamily: "Overlock-Regular",
      fontSize: "20px",
      textAlignment: "center",
      color: "#7a4f4b"
    },
    design17: {
      fontFamily: "Overlock-Regular",
      fontSize: "20px",
      textAlignment: "left",
      color: "#565656"
    },
    design18: {
      fontFamily: "Overlock-Regular",
      fontSize: "20px",
      textAlignment: "center",
      color: "#86007d"
    }

  }
  const defaultOtherCss = {
    design1: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design2: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design3: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design4: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design5: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design6: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design7: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design8: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design9: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design11: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design12: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design13: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design14: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design15: {
      fontFamily: "SuezOne-Regular",
      fontSize: "14px",
      textAlignment: "left"
    },
    design16: {
      fontFamily: "Overlock-Regular",
      fontSize: "14px",
      textAlignment: "left"
    },
    design17: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    },
    design18: {
      fontFamily: "Lato",
      fontSize: "14px",
      textAlignment: "left"
    }
  }
  const defaultImageShow = {
    design1: {
      image: design1Template
    },
    design2: {
      image: design2Template
    },
    design3: {
      image: design3Template
    },
    design4: {
      image: design4Template
    },
    design5: {
      image: design5Template
    },
    design6: {
      image: design7Template
    },
    design7: {
      image: design7Template
    },
    design8: {
      image: design8Template
    },
    design9: {
      image: design9Template
    },
    design11: {
      image: "",
      color:"#f4f4f4"
    },
    design12: {
      image: design12Template,
      color:"##fafcfd"
    },
    design13: {
      image: "",
      color:"#d5d3c2"
    },
    design14: {
      image: design14Template
    },
    design15: {
      image: design15Template
    },
    design16: {
      image: design16Template
    },
    design17: {
      image: design16Template
    },
    design18: {
      image: "",
      color:"#fff"
    }
  }
  const [titleColor, setTitleColor] = useState(defaultTitleCss?.[chooseDesign]?.color);
  const [messageColor, setMessageColor] = useState(defaultMessageCss?.[chooseDesign]?.color);
  const [otherColor, setOtherColor] = useState(defaultOtherCss?.[chooseDesign]?.color);
  const [patchColor, setPatchColor] = useState("");
  const [changeImage, setChangeImage] = useState("");
  const [totalPaginate, setTotalPaginate] = useState(1);
  const [forcePageActive, setForcePageActive] = useState(0);
  const [businessImage, setBusinessImage] = useState(true);
  const [titleFontFamily, setTitleFontFamily] = useState(defaultTitleCss?.[chooseDesign]?.fontFamily);
  const [messageFontFamily, setMessageFontFamily] = useState(defaultMessageCss?.[chooseDesign]?.fontFamily);
  const [otherFontFamily, setOtherFontFamily] = useState(defaultOtherCss?.[chooseDesign]?.fontFamily);
  const [fontSize, setFontSize] = useState({
    titleFontSize: "",
    messageFontSize: "",
    otherFontSize: "defaultOtherCss?.[chooseDesign]?.fontSize"
  });
  const [titleFontFamily1, setTitleFontFamily1] = useState(defaultTitleCss?.[chooseDesign]?.fontFamily);
  const [messageFontFamily1, setMessageFontFamily1] = useState(defaultMessageCss?.[chooseDesign]?.fontFamily);
  const [otherFontFamily1, setOtherFontFamily1] = useState(defaultOtherCss?.[chooseDesign]?.fontFamily);
  const [fontSize1, setFontSize1] = useState({
    titleFontSize: defaultTitleCss?.[chooseDesign]?.fontSize,
    messageFontSize: defaultMessageCss?.[chooseDesign]?.fontSize,
    otherFontSize: defaultOtherCss?.[chooseDesign]?.fontSize
  });
  const [fontStyle, setFontStyle] = useState({
    titleFontStyle: {
      bold: {
        fontWeight: 'inherit',
        is_active: false
      },
      italic: {
        fontStyle: "inherit",
        is_active: false
      },
      underline: {
        textDecoration: "none",
        is_active: false
      }
    },
    messageFontStyle: {
      bold: {
        fontWeight: 'inherit',
        is_active: false
      },
      italic: {
        fontStyle: "inherit",
        is_active: false
      },
      underline: {
        textDecoration: "none",
        is_active: false
      }
    },
    otherFontStyle: {
      bold: {
        fontWeight: 'inherit',
        is_active: false
      },
      italic: {
        fontStyle: "inherit",
        is_active: false
      },
      underline: {
        textDecoration: "none",
        is_active: false
      }
    }
  })
  const [textAlignment, setTextAlignment] = useState({
    titleTextAlign: {
      textAlign: defaultTitleCss?.[chooseDesign]?.textAlignment,
    },
    messageTextAlign: {
      textAlign: defaultMessageCss?.[chooseDesign]?.textAlignment,
    },
    otherTextAlign: {
      textAlign: defaultOtherCss?.[chooseDesign]?.textAlignment,
    }
  })
  const [titleText, setTitleText] = useState(MultiformReducer.data.showTemplateTitle);
  const [messageText, setMessageText] = useState(MultiformReducer.data.promptResponse);

  useEffect(() => {

    if(MultiformReducer.data.isSavedTemplate){
      setTitleColor(MultiformReducer.data?.title_style.color);
      setMessageColor(MultiformReducer.data?.message_style.color);
      setOtherColor(MultiformReducer.data?.other_style.color);
      setTitleFontFamily(MultiformReducer.data?.title_style.font_family);
      setMessageFontFamily(MultiformReducer.data?.message_style.font_family);
      setOtherFontFamily(MultiformReducer.data?.other_style.font_family);
      setTextAlignment({
        titleTextAlign: {
          textAlign: MultiformReducer.data?.title_style.text_alignment,
        },
        messageTextAlign: {
          textAlign: MultiformReducer.data?.message_style.text_alignment,
        },
        otherTextAlign: {
          textAlign: MultiformReducer.data?.other_style.text_alignment,
        }
      });
      setFontSize({
        titleFontSize: MultiformReducer.data?.title_style.font_size,
        messageFontSize: MultiformReducer.data?.message_style.font_size,
        otherFontSize: MultiformReducer.data?.other_style.font_size
      });

      setTitleFontFamily1(MultiformReducer.data?.title_style.font_family);
      setMessageFontFamily1(MultiformReducer.data?.message_style.font_family);
      setOtherFontFamily1(MultiformReducer.data?.other_style.font_family);
      setFontSize1({
        titleFontSize: MultiformReducer.data?.title_style.font_size,
        messageFontSize: MultiformReducer.data?.message_style.font_size,
        otherFontSize: MultiformReducer.data?.other_style.font_size
      });
    }else{
      setTitleColor(defaultTitleCss?.[chooseDesign].color);
      setMessageColor(defaultMessageCss?.[chooseDesign].color);
      setOtherColor(defaultOtherCss?.[chooseDesign].color);
      setTitleFontFamily(defaultTitleCss?.[chooseDesign].fontFamily);
      setMessageFontFamily(defaultMessageCss?.[chooseDesign].fontFamily);
      setOtherFontFamily(defaultOtherCss?.[chooseDesign].fontFamily);
      setTextAlignment({
        titleTextAlign: {
          textAlign: defaultTitleCss?.[chooseDesign].textAlignment,
        },
        messageTextAlign: {
          textAlign: defaultMessageCss?.[chooseDesign].textAlignment,
        },
        otherTextAlign: {
          textAlign: defaultOtherCss?.[chooseDesign].textAlignment,
        }
      });
      setFontSize({
        // titleFontSize: defaultTitleCss?.[chooseDesign].fontSize,
        // messageFontSize: defaultMessageCss?.[chooseDesign].fontSize,
        // otherFontSize: defaultOtherCss?.[chooseDesign].fontSize
      });


      setTitleFontFamily1(defaultTitleCss?.[chooseDesign].fontFamily);
      setMessageFontFamily1(defaultMessageCss?.[chooseDesign].fontFamily);
      setOtherFontFamily1(defaultOtherCss?.[chooseDesign].fontFamily);
      setFontSize1({
        // titleFontSize: defaultTitleCss?.[chooseDesign].fontSize,
        // messageFontSize: defaultMessageCss?.[chooseDesign].fontSize,
        // otherFontSize: defaultOtherCss?.[chooseDesign].fontSize
      });
    }
    

    

    
  }, [chooseDesign]);

  useEffect(()=>{
    // Update meta information in index.html
    document.title = "Choose layout Cards for Business template";
    document.querySelector('link[rel="canonical"]').setAttribute('href', "ttps://varnz.ai/choose-layouts");
    document.querySelector('meta[name="title"]').setAttribute('content', "Choose layout Cards for Business template")
    document.querySelector('meta[name="description"]').setAttribute('content', "Effortlessly set your selected business card designs with Varnz.ai. Customize your branding seamlessly and elevate your professional image instantly");
    // facebook meta tag
    document.querySelector('meta[property="og:url"]').setAttribute('content', "ttps://varnz.ai/choose-layouts");
    document.querySelector('meta[property="og:type"]').setAttribute('content', "website");
    document.querySelector('meta[property="og:title"]').setAttribute('content', "Choose layout Cards for Business template");
    document.querySelector('meta[property="og:description"]').setAttribute('content', "Effortlessly set your selected business card designs with Varnz.ai. Customize your branding seamlessly and elevate your professional image instantly");
    document.querySelector('meta[property="og:image"]').setAttribute('content', "");
    
    // twitter meta
    
    document.querySelector('meta[name="twitter:card"]').setAttribute('content', "summary_large_image");
    document.querySelector('meta[property="twitter:domain"]').setAttribute('content', "varnz.ai");
    document.querySelector('meta[property="twitter:url"]').setAttribute('content', "ttps://varnz.ai/choose-layouts");
    document.querySelector('meta[name="twitter:title"]').setAttribute('content', "Choose layout Cards for Business template");
    document.querySelector('meta[name="twitter:description"]').setAttribute('content', "Effortlessly set your selected business card designs with Varnz.ai. Customize your branding seamlessly and elevate your professional image instantly");
    document.querySelector('meta[name="twitter:image"]').setAttribute('content', "");
    },[])
 
  const handleTitleChange = (e) => {
    const newValue = e.target.value.replaceAll("<script>","").replaceAll("</script>","");
    setTitleText(newValue);
  }
  const handleMessageChange = (e) => {
    const newValue = e.target.value.replaceAll("<script>","").replaceAll("</script>","");
    setMessageText(newValue);
  }
  const notScript = (e)=>{
    if (e.key === "<") {
      e.preventDefault(); // Prevent the '<' character from being typed
      alert.error("< is prevented to write!", {
        timeout: 3000
      })
      // sendData("prevent-write")
    } else if (e.key === ">") {
      e.preventDefault();
      alert.error("> is prevented to write!", {
        timeout: 3000
      })
      // sendData("prevent-write")
    }
  }
  const handleTextAlignChange = (prop, value) => {
    setTextAlignment((oldAlign) => {
      return {
        ...oldAlign,
        [prop]: {
          textAlign: value
        }
      }
    });
  }
  const handleFontStyleChange = (prop, sub_prop, value, sub_value, active, main_prop) => {
    const data = {
      [sub_prop]: active ? value : sub_value,
      is_active: active
    }
    setFontStyle((prevFontStyle) => {
      return {
        ...prevFontStyle,
        [main_prop]: {
          ...prevFontStyle[main_prop],
          [prop]: data
        }
      }
    })
    // setTextStyle({ ...textStyle, [main_prop]: data });
  }

  const handleFontSizeChange = (event, prop) => {
    return setFontSize((oldData) => ({ ...oldData, [prop]: event.target.value }))
  }
  const handleTitleColorChange = (newColor) => {
    setTitleColor(newColor.hex);
  };
  const handleTitleFontFamilyChange = (event) => {
    setTitleFontFamily(event.target.value);
  };
  const handleMessageFontFamilyChange = (event) => {
    setMessageFontFamily(event.target.value);
  };
  const handleOtherFontFamilyChange = (event) => {
    setOtherFontFamily(event.target.value);
  };

  const handleMessageColorChange = (newColor) => {
    setMessageColor(newColor.hex);
  };

  const handleOtherColorChange = (newColor) => {
    setOtherColor(newColor.hex);
  };

  const handlePatchColorChange = (newColor) => {
    setPatchColor(newColor.hex);
  };
  const handlePageClick = (event) => {
    const page = event.selected + 1;
    getOnlineImage("", page);
    setForcePageActive(event.selected)
  }

  useEffect(() => {
    if (showElement.template === "") {
      document.getElementById("bg11").click();
    }
  }, [showElement]);
  const loop = [1];

  const changeBackgroundImage = (imageData, value = true,manual=true) => {
    // MultiformReducer.data.formData?.image
    let image = "";
    let abc = "";
    if(manual){
      if(imageData?.isSavedTemplate){
        image = imageData?.allFormData?.background_image ? imageData?.allFormData?.background_image : "";
        abc = imageData?.allFormData?.background_image ? true : false;
      }else{
        image = imageData?.formData?.image;
        abc = false;
      }
    }else{
      abc = true;
      image = imageData;
    }
    setChangeImage(image);
    setBusinessImage(abc)
  }
  const getOnlineImage = async (data, page = 1) => {
    const url = process.env.REACT_APP_SEARCH_IMAGE;
    setLoading(true);
    setImageResponse("");
    const response = await axios.get(url, {
      params: {
        search_image: data || searchValue,
        min_width: "550",
        min_height: "922",
        orientation: "vertical",
        page: page
      }
    });
    setImageResponse(response.data.imageData);
    const calcularTotalPagina = Math.ceil(
      (response.data.toalPaginate / 10)
    );
    setLoading(false);
    setTotalPaginate(calcularTotalPagina)
    document.getElementById("bg11").click();
  }
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  }

  const handleSearch = (data) => {
    setSearchValue(data);
    getOnlineImage(data);
    setForcePageActive(0)
  }
  const chooseTemplate = [
    "FirstDesign",
    "ThreeDesign",
    "ForthDesign",
    "FifthDesign",
    "SixthDesign",
    "SeventhDesign",
    "EightDesign",
    "NinthDesign",
    "TenthDesign",
    "SecondDesign",
    "FiftheenDesign"
  ];


  const defaultColors = [
    '#c8b79d',
    '#a3bec4',
    '#f3f1ec',
    '#d1d3d1',
    '#aebaa7',
    '#7f6d5a',
    '#b8a795',
    '#f9c8ca',
    '#ffe7dd',
    '#d5f9ff',
    '#ebffd5',
    '#ffe7b9',
    '#edc7ff',
    '#a3a3a3',
    '#88d0f5',
    '#ff9c9c',
  ];
  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (design) => {
    setChooseDesign(design);
    setShowSelected(design);
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const masonryOptions = {
    transitionDuration: 0,
  };

  const nextPage = () => {
    setShowElement({
      layout: "d-none",
      template: "",
      color: "d-none"
    });
  }

  return (
    <>
      <Businessheader />
      <div className="choose-bg-top">
        <div className="heading_portion section-header">
          <h2 className="cstm-h2" >
            <img className="bg-image-slect" src={chooseheading} alt="image1" />
            {
              !showElement.layout && "Choose Layout"
            }
            {
              !showElement.template && "Choose Background"
            }
            {
              !showElement.color && "Choose Styling"
            }

          </h2>
          <p>Customize your cards by selecting from a range of layouts, modifying the background, and changing text colors to suit your preferences. Once you've perfected your card, you can effortlessly download these messages on attractive cards and share them with your beloved ones.</p>
        </div>
        <ul className="how-to-choose">
          <li className={`${showElement.layout ? '' : 'active'}`}><span>1</span>Choose Layout</li>
          <li className={`${showElement.template ? '' : 'active'}`}><span>2</span>Choose Background</li>
          <li className={`${showElement.color ? '' : 'active'}`}><span>3</span>Choose Styling</li>
        </ul>
        <div className="choose-template-elements">
          <img className="elemnt1" src={elemnt1} alt="image1" />
          <img className="elemnt2" src={elemnt2} alt="image2" />
          <img className="elemnt3" src={elemnt3} alt="image3" />
          <img className="elemnt4" src={elemnt4} alt="image4" />
          <img className="elemnt5" src={elemnt5} alt="image5" />
        </div>
      </div>
      <div className={`choose-edit-post business-step-1 animate__animated animate__fadeInLeft ${showElement.layout}`}>

        <div className="choose-templat-buttons select-any-tabs">
          <div className="cstm-container">
            <h2 className="cstm-h2" >Try From Different Layout's</h2>
            <button className='btn btn-success btn-lg mb-5 blue-btn cstm-btn' onClick={nextPage}>Next <i class="fal fa-angle-right"></i></button>
          </div>
        </div>

        <div className="cstm-container">
          <div className="choose-template">
            <div className="choose-background">
              <ul className="background_lists">
                <Masonry
                  options={masonryOptions}
                  elementType={'div'}
                >
                  {
                    MultiformReducer.data ? (
                      Object.keys(allTemplates).map((key, index) => {
                        let Design = allTemplates[key];
                        return <li 
                        ref={showSelected === key ? abc : null }
                        className="business-list-item bkgnd-listing" onClick={() => handleClick(key)} id={`bgg-${index}`}>
                          <input type="radio" id={`bg-${index}`} name="bg-select1" value="choose-image"
                            checked={showSelected === key}
                          />
                          <label htmlFor={`bg-${index}`}>
                            {
                              console.log(MultiformReducer.data.isSavedTemplate,"jkjkdjsakjfdksjfk")
                            }
                            {
                              !MultiformReducer.data.isSavedTemplate ? (
                                <Design
                                promptResponse={MultiformReducer.data.promptResponse}
                                resultImage={MultiformReducer.data.formData?.image}
                                formData={MultiformReducer.data.formData}
                                corporateUserInfo={MultiformReducer.data.corporateUserInfo}
                                showTemplateTitle={MultiformReducer.data.showTemplateTitle}
                                designType={key}
                                category={MultiformReducer.data.category}
                                occasion={MultiformReducer.data.occasion}
                                prompt={MultiformReducer.data.prompt}
                                setMasonaryValue={MultiformReducer.data.setMasonaryValue}
                                showFooterButton={true}
                                isSavedEdit={MultiformReducer.data.isSavedTemplate}
                                allFormData={MultiformReducer.data.allFormData}
                              />
                              ):(
                                <Design
                                promptResponse={MultiformReducer.data.promptResponse}
                                resultImage={MultiformReducer.data.formData?.image}
                                formData={MultiformReducer.data.formData}
                                corporateUserInfo={MultiformReducer.data.corporateUserInfo}
                                showTemplateTitle={MultiformReducer.data.showTemplateTitle}
                                designType={key}
                                category={MultiformReducer.data.category}
                                occasion={MultiformReducer.data.occasion}
                                prompt={MultiformReducer.data.prompt}
                                setMasonaryValue={MultiformReducer.data.setMasonaryValue}
                                showFooterButton={true}
                                isSavedEdit={MultiformReducer.data.isSavedTemplate}
                                allFormData={MultiformReducer.data.allFormData}
  
                                otherColor={otherColor}
                                responseColor={messageColor}
                                titleColor={titleColor}
                                titleFontFamily={titleFontFamily}
                                messageFontFamily={messageFontFamily}
                                otherFontFamily={otherFontFamily}
                                fontSize={fontSize}
                                fontStyle={fontStyle}
                                textAlignment={textAlignment}
                                onlineImage={MultiformReducer.data?.allFormData?.background_image}
                                // backgroundImage={businessImage}
                              />
                              )
                            }
                           
                          </label>
                        </li>
                      })
                    ) : (
                      null
                    )
                  }
                </Masonry>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* 2nd step */}
      <div className={`choose-edit-post business-step-2 choose-background animate__animated animate__fadeInRight ${showElement.layout === "" ? "d-none" : ""}`}>
        <div className="selected-bg-main" >
          <div className="choose-temp-color-elments" >
            <img className="choosecolor2" src={choosecolor2} alt="choosecolor1" />
          </div>
          <div className="cstm-container">
            {
              !showElement.template && <div className="choose-background-left animate__animated animate__fadeInLeft">
                <div className="choose-templat-buttons prev">
                  <button className='btn btn-success btn-lg mb-5 blue-btn cstm-btn' onClick={() => { setShowElement({ layout: "", template: "d-none", color: "d-none" }) }} > <i class="fal fa-angle-left"></i> Previous</button>
                  <button className='btn btn-success btn-lg mb-5 blue-btn cstm-btn' onClick={() => { setShowElement({ layout: "d-none", template: "d-none", color: "" }) }} disabled={chooseDesign ? false : true}><i class="fas fa-paint-brush-alt"></i> Change Styling <i class="fal fa-angle-right"></i></button>
                </div>
                <h2>Change background</h2>
                <p>Customize your cards by selecting from a range of layouts, modifying the background, and changing text colors to suit your preferences. Once you've perfected your card, you can effortlessly download these messages on attractive cards and share them with your beloved ones.</p>
                <div className="choose-background-search" >
                  <input
                    type="search"
                    id="gsearch"
                    name="gsearch"
                    placeholder="Search Background"
                    value={searchValue}
                    onChange={handleChange}
                  />
                  <i class="far fa-search" onClick={() => { getOnlineImage(); setForcePageActive(0) }}></i>
                </div>
                <ul className="choose-background-tags">
                  {
                    showText.map((data, index) => {
                      return <li>
                        <input type="radio" id={`bg-tag${index}`} name="bg-tag" onClick={() => { handleSearch(data) }}
                          checked={searchValue === data ? true : false}
                        />
                        <label for={`bg-tag${index}`}>{data}</label>
                      </li>
                    })
                  }
                </ul>

                <div className='change-bg-mobile'>
                {loading && <div style={{ width: "100%", height: "100%" }} className="please-wait unique">
																<div class="skype-loader">
																	<div class="dot">
																		<div class="first"></div>
																	</div>
																	<div class="dot"></div>
																	<div class="dot"></div>
																	<div class="dot"></div>
																</div>
																<p>Please wait...</p>
                  </div>}
                  {imageResponse &&
                    <Carousel responsive={responsive}>
                      {
                        imageResponse ? (
                          imageResponse.map((data, index) => {
                            return <div onClick={() => changeBackgroundImage(data.image_url,true,false)} className='mx-1'>
                              <div>
                                <img src={data.image_url} width="100px" height="100px" />
                              </div>
                            </div>
                          })
                        ) : null
                      }
                    </Carousel>
                  }
                  {
                    searchValue && <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      // pageRangeDisplayed={2}
                      pageCount={totalPaginate}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination justify-content-center"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      forcePage={forcePageActive}
                      marginPagesDisplayed={1}
                      // pageRange={[1,2,3,4,5,6,7,8,9,10]}
                      // breakAriaLabels={"forward"}
                    />
                  }
                </div>

                <div className="choose-change-bg">
                  <div className={`cstm-container ${showElement.template}`}>
                    <div className="choose-template">
                      <div className="choose-background" >
                        <ul className="background_lists" >
                          <Masonry
                            options={masonryOptions}
                            elementType={'div'}
                          >
                            {
                              MultiformReducer.data && !loading && 
                              <li onClick={() => changeBackgroundImage(MultiformReducer.data, false,true)} className='backgrnd-listing'>
                                <input type="radio" id="bg11" name="bg-select" value="choose-image" />
                                <label 
                                  htmlFor="bg11" 
                                  style={{ width: `${defaultImageShow[chooseDesign].image ? "" : "300px"}`,height: `${defaultImageShow[chooseDesign].image ? "" : "300px"}`,backgroundColor: defaultImageShow[chooseDesign].color }}
                                >
                                
                                  { !loading && defaultImageShow[chooseDesign].image && 
                                    <img className="bg-image-slect" src={MultiformReducer.data?.isSavedTemplate ? MultiformReducer.data?.allFormData?.background_image ? MultiformReducer.data?.allFormData?.background_image : defaultImageShow[chooseDesign].image : defaultImageShow[chooseDesign].image} alt="image1" />
                                  }
                                </label>
                              </li>
                            }
                            {loading && <div style={{ width: "100%", height: "100%" }} className="please-wait unique">
																<div class="skype-loader">
																	<div class="dot">
																		<div class="first"></div>
																	</div>
																	<div class="dot"></div>
																	<div class="dot"></div>
																	<div class="dot"></div>
																</div>
																<p>Please wait...</p>
															</div>}
                            {
                              imageResponse && imageResponse.map((data, index) => {
                                // console.log(data,"sucesssss",index)
                                return <li onClick={() => changeBackgroundImage(data.image_url,true,false)} className='backgrnd-listing'>
                                  <input type="radio" id={`bg${index}`} name="bg-select" value="choose-image" />
                                  <label for={`bg${index}`}><img className="bg-image-slect" src={data.image_url} alt="image1" /></label>
                                </li>
                              })
                            }
                          </Masonry>
                          {
                            searchValue && <ReactPaginate
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={handlePageClick}
                              // pageRangeDisplayed={2}
                              pageCount={totalPaginate}
                              previousLabel="<"
                              renderOnZeroPageCount={null}
                              containerClassName="pagination justify-content-center"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              activeClassName="active"
                              forcePage={forcePageActive}
                              marginPagesDisplayed={1}
                              // pageRange={[1,2,3,4,5,6,7,8,9,10]}
                              // breakAriaLabels={"forward"}
                            />
                          }

                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            }
            {
              !showElement.color && <div className="choose-background-left choose-template-color animate__animated animate__fadeInRight">
                <div className="choose-templat-buttons">
                  <h2>Change Styling</h2>
                  <div className="choose-templat-buttons prev">
                    <button className='btn btn-success btn-lg mb-5 blue-btn cstm-btn' onClick={() => { setShowElement({ layout: "d-none", template: "", color: "d-none" }) }} > <i class="fal fa-angle-left"></i> Previous</button>
                  </div>
                </div>
                <div className="choose-color-tabs choose-corprate-template">
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
                      <Tab label="Title" {...a11yProps(0)} />
                      <Tab label="Message" {...a11yProps(1)} />
                      <Tab label="Other" {...a11yProps(2)} />
                      {
                        showColorPatch.indexOf(chooseDesign) != -1 && <Tab label="Patch Color" {...a11yProps(2)} />
                      }
                    </Tabs>
                  </Box>
                  <div
                    role="tabpanel"
                    hidden={value !== 0}
                    id={`simple-tabpanel-0`}
                    aria-labelledby={`simple-tab-0`}
                  // {...other}
                  >
                    {value === 0 && (
                      <Box sx={{ p: 3 }}>
                        <Typography>
                          <div className='choose-font-styeling font-styling text-styling'>
                            <ul>
                              <li><span className="text-style-heading">Font Family</span>
                                <select
                                  className='choose-font-styeling-family'
                                  style={{ fontFamily: titleFontFamily }}
                                  value={titleFontFamily}
                                  onChange={handleTitleFontFamilyChange}
                                >
                                  <option value="">Select a font family</option>
                                  <option value={titleFontFamily1} style={{ fontFamily: `${titleFontFamily1}`, fontSize: "20px", background: "#ddd" }}>{titleFontFamily1}</option>
                                  {FontFamily.map((fontFamily, index) => (
                                    <option key={index} value={fontFamily} style={{ fontFamily: `${fontFamily}`, fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                  ))}
                                </select>
                              </li>

                              <li><span className="text-style-heading">Font Style</span>
                                <ul className="text-style">
                                  <li style={{ background: `${fontStyle.titleFontStyle.bold.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("bold", "fontWeight", "bold", "inherit", !fontStyle.titleFontStyle.bold.is_active, "titleFontStyle")}><i class="fa-light fa-b"></i></li>
                                  <li style={{ background: `${fontStyle.titleFontStyle.italic.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("italic", "fontStyle", "italic", "inherit", !fontStyle.titleFontStyle.italic.is_active, "titleFontStyle")}><i class="far fa-italic"></i></li>
                                  <li style={{ background: `${fontStyle.titleFontStyle.underline.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("underline", "textDecoration", "underline", "none", !fontStyle.titleFontStyle.underline.is_active, "titleFontStyle")}><i class="far fa-underline"></i></li>
                                </ul>
                              </li>
                              <li><span className="text-style-heading">Font Size</span>
                                <select
                                  className='choose-font-styeling-family'
                                  // style={{ fontFamily: titleFontFamily }} 
                                  value={fontSize.titleFontSize}
                                  onChange={(e) => handleFontSizeChange(e, "titleFontSize")}
                                >
                                  <option value="">Select a font size</option>
                                  <option value={fontSize1.titleFontSize} style={{ fontSize: "20px", background: "#ddd" }}>{fontSize1.titleFontSize}</option>
                                  {fontSizesArray.map((fontFamily, index) => (
                                    <option key={index} value={fontFamily} style={{ fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                  ))}
                                </select>
                              </li>
                              <li><span className="text-style-heading">Text Alignment</span>
                                <ul className="text-style text-align">
                                  <li onClick={() => handleTextAlignChange("titleTextAlign", "left")}>
                                    <input
                                      type="radio"
                                      id="align1"
                                      name="align"
                                      checked={textAlignment.titleTextAlign.textAlign == "left"}
                                    />
                                    <label htmlFor="align1">
                                      <i class="fas fa-align-left"></i>
                                    </label>
                                  </li>
                                  <li onClick={() => handleTextAlignChange("titleTextAlign", "center")}>
                                    <input
                                      type="radio"
                                      id="align2"
                                      name="align"
                                      checked={textAlignment.titleTextAlign.textAlign == "center"}
                                    />
                                    <label htmlFor="align2">
                                      <i class="fas fa-align-center"></i>
                                    </label>
                                  </li>
                                  <li onClick={() => handleTextAlignChange("titleTextAlign", "right")}>
                                    <input
                                      type="radio"
                                      id="align3"
                                      name="align"
                                      checked={textAlignment.titleTextAlign.textAlign == "right"}
                                    />
                                    <label htmlFor="align3">
                                      <i class="fas fa-align-right"></i>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                              <li><span className="text-style-heading">Title</span>
                                <textarea
                                  class="cstm-form"
                                  placeholder="Type here"
                                  name="message"
                                  value={titleText}
                                  // rows={1}
                                  type="text"
                                  style={{ border: '1px dashed #000', padding: '3px', width: 'inherit', height: 'auto', textAlign: `${textAlignment.titleTextAlign.textAlign}` }}
                                  onKeyDown={notScript}
                                  onChange={(e) => handleTitleChange(e)}
                                />
                              </li>
                            </ul>
                          </div>
                          <SketchPicker color={titleColor} onChange={handleTitleColorChange} presetColors={defaultColors} />

                        </Typography>
                      </Box>
                    )}
                  </div>
                  <div
                    role="tabpanel"
                    hidden={value !== 1}
                    id={`simple-tabpanel-1`}
                    aria-labelledby={`simple-tab-1`}
                  >
                    {value === 1 && (
                      <Box sx={{ p: 3 }}>
                        <Typography><div className='choose-font-styeling font-styling text-styling'>
                          <ul>
                            <li><span className="text-style-heading">Font Family</span>
                              <select
                                className='choose-font-styeling-family'
                                style={{ fontFamily: messageFontFamily }}
                                value={messageFontFamily}
                                onChange={handleMessageFontFamilyChange}
                              >
                                <option value="">Select a font family</option>
                                <option value={messageFontFamily1} style={{ fontFamily: `${messageFontFamily1}`, fontSize: "20px", background: "#ddd" }}>{messageFontFamily1}</option>
                                {FontFamily.map((fontFamily, index) => (
                                  <option key={index} value={fontFamily} style={{ fontFamily: `${fontFamily}`, fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                ))}
                              </select>
                            </li>
                            <li><span className="text-style-heading">Font Style</span>
                              <ul className="text-style">
                                <li style={{ background: `${fontStyle.messageFontStyle.bold.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("bold", "fontWeight", "bold", "inherit", !fontStyle.messageFontStyle.bold.is_active, "messageFontStyle")}><i class="fa-light fa-b"></i></li>
                                <li style={{ background: `${fontStyle.messageFontStyle.italic.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("italic", "fontStyle", "italic", "inherit", !fontStyle.messageFontStyle.italic.is_active, "messageFontStyle")}><i class="far fa-italic"></i></li>
                                <li style={{ background: `${fontStyle.messageFontStyle.underline.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("underline", "textDecoration", "underline", "none", !fontStyle.messageFontStyle.underline.is_active, "messageFontStyle")}><i class="far fa-underline"></i></li>
                              </ul>
                            </li>
                            <li><span className="text-style-heading">Font Size</span>
                              <select
                                className='choose-font-styeling-family'
                                // style={{ fontFamily: titleFontFamily }} 
                                value={fontSize.messageFontSize}
                                onChange={(e) => handleFontSizeChange(e, "messageFontSize")}
                              >
                                <option value="">Select a font size</option>
                                <option value={fontSize1.messageFontSize} style={{ fontSize: "20px", background: "#ddd" }}>{fontSize1.messageFontSize}</option>
                                {fontSizesArray.map((fontFamily, index) => (
                                  <option key={index} value={fontFamily} style={{ fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                ))}
                              </select>
                            </li>
                            <li><span className="text-style-heading">Text Alignment</span>
                              <ul className="text-style text-align">
                                <li onClick={() => handleTextAlignChange("messageTextAlign", "left")}>
                                  <input
                                    type="radio"
                                    id="align1"
                                    name="align"
                                    checked={textAlignment.messageTextAlign.textAlign == "left"}
                                  />
                                  <label htmlFor="align1">
                                    <i class="fas fa-align-left"></i>
                                  </label>
                                </li>
                                <li onClick={() => handleTextAlignChange("messageTextAlign", "center")}>
                                  <input
                                    type="radio"
                                    id="align2"
                                    name="align"
                                    checked={textAlignment.messageTextAlign.textAlign == "center"}
                                  />
                                  <label htmlFor="align2">
                                    <i class="fas fa-align-center"></i>
                                  </label>
                                </li>
                                <li onClick={() => handleTextAlignChange("messageTextAlign", "right")}>
                                  <input
                                    type="radio"
                                    id="align3"
                                    name="align"
                                    checked={textAlignment.messageTextAlign.textAlign == "right"}
                                  />
                                  <label htmlFor="align3">
                                    <i class="fas fa-align-right"></i>
                                  </label>
                                </li>
                              </ul>
                            </li>
                            <li><span className="text-style-heading">Message</span>
                              <textarea
                                class="cstm-form"
                                placeholder="Type here"
                                name="message"
                                value={messageText}
                                rows={7}
                                type="text"
                                style={{ border: '1px dashed #000', padding: '3px', width: 'inherit', height: 'auto', textAlign: `${textAlignment.messageTextAlign.textAlign}` }}
                                onChange={(e) => handleMessageChange(e)}
                                onKeyDown={notScript}
                              ></textarea>
                            </li>
                          </ul>
                        </div>
                          <SketchPicker color={messageColor} onChange={handleMessageColorChange} presetColors={defaultColors} />
                        </Typography>
                      </Box>
                    )}
                  </div>
                  <div
                    role="tabpanel"
                    hidden={value !== 2}
                    id={`simple-tabpanel-2`}
                    aria-labelledby={`simple-tab-2`}
                  >
                    {value === 2 && (
                      <Box sx={{ p: 3 }}>
                        <Typography><div className='choose-font-styeling font-styling text-styling other-styling'>
                          <ul>
                            <li><span className="text-style-heading">Font Family</span>
                              <select
                                className='choose-font-styeling-family'
                                style={{ fontFamily: otherFontFamily }}
                                value={otherFontFamily}
                                onChange={handleOtherFontFamilyChange}
                              >
                                <option value="">Select a font family</option>
                                <option value={otherFontFamily1} style={{ fontFamily: `${otherFontFamily1}`, fontSize: "20px", background: "#ddd" }}>{otherFontFamily1}</option>
                                {FontFamily.map((fontFamily, index) => (
                                  <option key={index} value={fontFamily} style={{ fontFamily: `${fontFamily}`, fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                ))}
                              </select>
                            </li>
                            <li><span className="text-style-heading">Font Style</span>
                              <ul className="text-style">
                                <li style={{ background: `${fontStyle.otherFontStyle.bold.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("bold", "fontWeight", "bold", "inherit", !fontStyle.otherFontStyle.bold.is_active, "otherFontStyle")}><i class="fa-light fa-b"></i></li>
                                <li style={{ background: `${fontStyle.otherFontStyle.italic.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("italic", "fontStyle", "italic", "inherit", !fontStyle.otherFontStyle.italic.is_active, "otherFontStyle")}><i class="far fa-italic"></i></li>
                                <li style={{ background: `${fontStyle.otherFontStyle.underline.is_active ? '#e0e2e5' : ''}` }} onClick={() => handleFontStyleChange("underline", "textDecoration", "underline", "none", !fontStyle.otherFontStyle.underline.is_active, "otherFontStyle")}><i class="far fa-underline"></i></li>
                              </ul>
                            </li>
                            <li><span className="text-style-heading">Font Size</span>
                              <select
                                className='choose-font-styeling-family'
                                // style={{ fontFamily: titleFontFamily }} 
                                value={fontSize.otherFontSize}
                                onChange={(e) => handleFontSizeChange(e, "otherFontSize")}
                              >
                                <option value="">Select a font size</option>
                                <option value={fontSize1.otherFontSize} style={{ fontSize: "20px", background: "#ddd" }}>{fontSize1.otherFontSize}</option>
                                {fontSizesArray.map((fontFamily, index) => (
                                  <option key={index} value={fontFamily} style={{ fontSize: "20px", background: "#ddd" }}>{fontFamily}</option>
                                ))}
                              </select>
                            </li>
                            <li><span className="text-style-heading">Text Alignment</span>
                              <ul className="text-style text-align">
                                <li onClick={() => handleTextAlignChange("otherTextAlign", "left")}>
                                  <input
                                    type="radio"
                                    id="align1"
                                    name="align"
                                    checked={textAlignment.otherTextAlign.textAlign == "left"}
                                  />
                                  <label htmlFor="align1">
                                    <i class="fas fa-align-left"></i>
                                  </label>
                                </li>
                                <li onClick={() => handleTextAlignChange("otherTextAlign", "center")}>
                                  <input
                                    type="radio"
                                    id="align2"
                                    name="align"
                                    checked={textAlignment.otherTextAlign.textAlign == "center"}
                                  />
                                  <label htmlFor="align2">
                                    <i class="fas fa-align-center"></i>
                                  </label>
                                </li>
                                <li onClick={() => handleTextAlignChange("otherTextAlign", "right")}>
                                  <input
                                    type="radio"
                                    id="align3"
                                    name="align"
                                    checked={textAlignment.otherTextAlign.textAlign == "right"}
                                  />
                                  <label htmlFor="align3">
                                    <i class="fas fa-align-right"></i>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                          <SketchPicker color={otherColor} onChange={handleOtherColorChange} presetColors={defaultColors} />
                        </Typography>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            }
            <div className="selected-template" >
              {MultiformReducer.data &&
                loop.map((data) => {
                  if (chooseDesign) {
                    let ChooseDesign = allTemplates[chooseDesign];
                    return <ChooseDesign
                      otherColor={otherColor}
                      responseColor={messageColor}
                      titleColor={titleColor}
                      titleFontFamily={titleFontFamily}
                      messageFontFamily={messageFontFamily}
                      otherFontFamily={otherFontFamily}
                      fontSize={fontSize}
                      fontStyle={fontStyle}
                      textAlignment={textAlignment}
                      allFormData={MultiformReducer.data.allFormData}
                      corporateUserInfo={MultiformReducer.data.corporateUserInfo}
                      promptResponse={messageText}
                      designType={chooseDesign}
                      category={MultiformReducer.data.category}
                      occasion={MultiformReducer.data.occasion}
                      prompt={MultiformReducer.data.prompt}

                      
                      resultImage={MultiformReducer.data.formData?.image}
                      formData={MultiformReducer.data.formData}
                      showTemplateTitle={titleText}
                      
                      onlineImage={changeImage}
                      backgroundImage={businessImage}
                      setMasonaryValue={MultiformReducer.data.setMasonaryValue}
                      isEditPage={true}

                      


                      isSavedEdit={MultiformReducer.data.isSavedTemplate}
                    />
                  }
                })
              }
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};
