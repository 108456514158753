function loginUser(user) {
    setUser(user);
    localStorage.setItem('IsLogin', true);
}

function isLogin() {
    return localStorage.getItem('IsLogin') ? true : false;
}
//
function SetFreeMessageUsers(status) {
    setStatus(status)
    localStorage.setItem('messageData', true);
}
function GetFreeMessageStatus() {
    return localStorage.getItem('messageData') ? true : false;
}
function setStatus(status) {
    localStorage.setItem('statusr_user',status);
}
//

function logout() {
    localStorage.clear();
}

function setUser(user) {
    localStorage.setItem('guitar_user', JSON.stringify(user));
}

function getAuth() {
    return JSON.parse(localStorage.getItem('guitar_user'));
}

function getStore() {
    let store = {
        shopify_access_token: "",
        shopify_url: ""
    }

    let data = JSON.parse(localStorage.getItem('guitar_user'));

    if (data.stores) {
        if (data.stores.length) {
            store = data.stores[0];
        }
    }

    return store;

}

module.exports = {
    loginUser: loginUser,
    isLogin: isLogin,
    logout: logout,
    setUser: setUser,
    getStore: getStore,
    getAuth: getAuth,
    SetFreeMessageUsers:SetFreeMessageUsers,
    GetFreeMessageStatus:GetFreeMessageStatus
};