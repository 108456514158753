import React, { useState } from "react";
import Cookies from "js-cookie";

// email validation- signup,forgot_password,signin,invite_user_using_email

// export function ValidateEmail(email) {
//   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   const hasCom = email.includes(".com");

//   if (!regex.test(email) || !hasCom) {
//     return false;
//   }
//   return true;
// }

// export const handleEmailChange = (
//   e,
//   setEmail,
//   setEmailError,
//   setErrorMessage,
//   ValidateEmail
// ) => {
//   setEmail(e.target.value);
//   if (!ValidateEmail(e.target.value)) {
//     setEmailError("Invalid email address.");
//     setErrorMessage("Please enter a valid email address.");
//   } else {
//     setEmailError("");
//     setErrorMessage("");
//   }
// };

// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
//   if (email === '') {
//     setEmailError('Please enter an email address.');
//     setErrorMessage('');
//   } else if (!emailRegex.test(email)) {
//     setEmailError('Invalid email address.');
    // setErrorMessage('Please enter a valid email address.');
export const handleEmailChange = (
  e,
  setEmail,setErrorMessage,setEmailError
) => {
  setEmail(e.target.value);
    setErrorMessage('');
    setEmailError("")
};
export const handleEmailBlur = (
  email,
  setEmailError,
  setErrorMessage,
  ValidateEmail
) => {
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  
  if(email.length > 0 && (email.length < 6 || email.length > 35))
  {
    setEmailError("Sorry,your email must be between 6 and 35 characters long.");
    return;
  }
  // if (email.length === '') {
  //   setEmailError('Please enter an email address.');
  //   setErrorMessage('');
  // } else 
  if (email.length > 0 && !emailRegex.test(email)) {
    setEmailError('Invalid email address.');
    // setErrorMessage('Please enter a valid email address.');
  } else {
    setEmailError('');
    setErrorMessage('');
  }
};
export function ValidateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const coRegex = /^[^\s@]+@[^\s@]+\.com$/;
  if (!regex.test(email) || !coRegex.test(email)) {
    return false;
  }
  return true;
}
// export function ValidateEmail(email) {
//   const regex = /^[^\s@]+@[^\s@]+\.co[^\s@]+$/;
//   if (!regex.test(email) ) {
//     return false;
//   } 
//   return true;
// }

// Password validation- signup,reset_password
export const handlePasswordChange = (
  e,
  setPassword,
  setPasswordError,
  setErrorMessage
) => {
  setPassword(e.target.value);
  // if (
  //   !/(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/.test(
  //     e.target.value
  //   )
  // ) {
  //   setPasswordError(
  //     ""
  //   );
  // } else {
  //   setPasswordError("");
  //   setErrorMessage("");
  // }
};





export const handleConfirmPasswordChange = (
  e,
  setConfirmPassword,
  setConfirmPasswordError,
  setErrorMessage,
  password
) => {
  setConfirmPassword(e.target.value);
  if (e.target.value !== password) {
    setConfirmPasswordError("Passwords do not match");
  } else {
    setConfirmPasswordError("");
    setErrorMessage("");
  }
};

export const passwordAndConfirmPasswordMatch = (
  password,
  confirmPassword,
  setErrorMessage,
  passwordError,
  confirmPasswordError
) => {
  if (!password || !confirmPassword) {
    setErrorMessage("Please fill out all fields.");
    return;
  }
  if(password.length < 8){
    setErrorMessage("Password must be at least 8 characters long.");
    return;
  }
  if (passwordError || confirmPasswordError) {
    setErrorMessage("Please fix all errors before submitting.");
    return;
  }
};

//   export const EmailAndPassMatch =(email,password,confirmPassword,setErrorMessage,emailError,passwordError,confirmPasswordError)=>{
//     if (!email || !password || !confirmPassword) {
//         setErrorMessage("Please fill out all fields.");
//         return;
//       }
//       if (emailError || passwordError || confirmPasswordError) {
//         setErrorMessage("Please fix all errors before submitting.");
//         return;
//       }
//   }
// encryptData

export function encryptData(data) {
  return btoa(data);
}
export function checkOtpValidation(otp, setError) {
  if (otp.trim() === "") {
    setError("Please enter an OTP");
    return;
  }
  if (!/^\d{6}$/.test(otp)) {
    setError("Please enter a valid OTP");
    return;
  }

  setError("");

  // if (newOtp === "") {
  //   setError("Please enter an OTP");
  // } else {
  //   setError("");
  // }
}

export function SucessAlert(typeData, msgType) {
  if (typeData == "email_verification") {
    var titleData = "Verification Completed!";
    var textData = "Your email has been verified successfully!";
  } else if (typeData == "account_creation") {
    var titleData = "Congratulation...";
    var textData = "Your account has been successfully created.";
  }else if(typeData == "profile_updated"){
    var titleData = "Profile Updated...";
    var textData = "Profile updated successful.";
  }
  // Swal.fire({
  //   icon: msgType,
  //   title: titleData,
  //   text: textData,
  //   confirmButtonText: "OK",
  //   customClass: {
  //     icon: "my-icon-class",
  //   },
  // });
}

// compleProfile

export function handleNameChange(e, setName, setNameError, setErrorMessage) {
  setName(e.target.value);
   const name = e.target.value
   if (!name) {
    setNameError("Please enter your name.");
    setErrorMessage("");
  } else if (!/^\D+$/.test(name)) {
    setNameError("Name cannot be a number.");
    setErrorMessage("");
  } else if (name.trim().length === 0) {
    setNameError("Name cannot be just spaces.");
    setErrorMessage("");
  } else if (name.length > 20) {
    setNameError("Name must be at most 20 characters long.");
    setErrorMessage("");
  } else {
    setName(name);
    setNameError("");
    setErrorMessage("");
  }


  // if (!e.target.value) {
  //   setNameError("Please enter your name.");
  // } else if (e.target.value.length > 20) {
  //   setNameError("Name must be at least 20 characters long.");
  // } else {
  //   setNameError("");
  //   setErrorMessage("");
  // }
}

export function handleGenderChange(
  e, 
  setGender,
  setGenderError,
  setErrorMessage
) {
  setGender(e.target.value);
  if (!e.target.value) {
    setGenderError("Please select your gender.");
  } else if(e.target.value!='female' || e.target.value!='male'){
    // setGenderError("Please select your gender.");
  }else {
    setGenderError("");
    setErrorMessage("");
  } 
}

export function handleDateOfBirthChange(
  e,
  setDateOfBirth,
  setDateOfBirthError,
  setErrorMessage
) {
  setDateOfBirth(e.target.value);
  if (!e.target.value) {
    setDateOfBirthError("Please enter your date of birth.");
  } else {
    setDateOfBirthError("");
    setErrorMessage("");
  }
  if (e.target.value) {
    const specificDate = new Date(e.target.value);
    const currentDate = new Date();
    const isBefore = specificDate > currentDate;
    if(isBefore)
    {
      
      setDateOfBirthError("Please enter Valid date of birth.");

      return false;
    }
    
  }
}

export function handleReminderdateChange(
  e,
  setReminderdate,
  setReminderdateError,
  setErrorMessage
) {
  setReminderdate(e.target.value);
  if (!e.target.value) {
    setReminderdateError("Please enter your Reminder date.");
  } else {
    setReminderdateError("");
    setErrorMessage("");
  }
}

export function handlePersonalityChange(
  e,
  setPersonality,
  setPersonalityError, 
  setErrorMessage
) {
  setPersonality(e.value);
  if (!e.value) {
    setPersonalityError("Please enter your personality.");
  } else {
    setPersonalityError("");
    setErrorMessage("");
  }
}

export function handleCountryChange(
  e,
  setCountry,
  setCountryError, 
  setErrorMessage
) {
  setCountry(e.value);
  if (!e.value) {
    setCountryError("Please enter your personality.");
  } else {
    setCountryError("");
    setErrorMessage("");
  }
}

// export function handleCurrencyChange(
//   e,
//   setCurrency,
//   setCurrencyError, 
//   setErrorMessage
// ) {
//   setCurrency(e.value);
//   if (!e.value) {
//     setCurrencyError("Please enter your currency.");
//   } else {
//     setCurrencyError("");
//     setErrorMessage("");
//   }
// }

// empty validation

export function validateDataInput(
  name,
  setNameError,
  gender,
  setGenderError,
  dateOfBirth,
  setDateOfBirthError,
  personality,
  setPersonalityError,
  country,
  setCountryError,
  // currency,
  // setCurrencyError
) {
  if (!name) {
    setNameError("Please enter your name.");
    return false;
  }
  if (name.length < 20) {
    setNameError("Name must be at least 20 characters long.");
    return false;
  }
  if (!gender) {
    setGenderError("Please select your gender.");
    return false;
  }
  if (!dateOfBirth) {
    setDateOfBirthError("Please enter your date of birth.");
    return false;
  }
  if (!personality) {
    setPersonalityError("Please enter your personality.");
    return false;
  }
  if (!country) {
    setCountryError("Please enter your country.");
    return false;
  }
  // if (!currency) {
  //   setCurrencyError("Please enter your currency.");
  //   return false;
  // }

  return true;
}

// setCommonCokkies

export function setUserCokkie(response, sectionType) {
  
  let userInfromation = response.data;
  const cookieValue = JSON.stringify({ userID: userInfromation });
  Cookies.set("userIDget", cookieValue, { expires: 1 });
  if (sectionType == "autoMatic") {
    Cookies.set("userIDget", cookieValue, { expires: 1 });

    

    
  } else if (sectionType == "signin") {
    let Tokenstore = userInfromation?.token;
    let Emailstore = userInfromation?.data?.email;

    Cookies.set("userIDget", cookieValue, { expires: 1 });
    Cookies.set("userIDgetForgotPass", cookieValue);
    let statusstore = userInfromation?.status;

    if (statusstore == 1) {
      localStorage.setItem("isUserAuthenticated", true);
    }
  }
}
