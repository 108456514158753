import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Result_Overlay } from './commonFunction';
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from 'react-masonry-component';
import height from "./commonFunction/height";
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }

const backgroundFrame = "";


export const QuoteDesign3 = ({ data, templateData, title, index, handleClick, clickedTabs, sendData, showTabs, message, shareImage, color, defaultImage, overlay,setDownloadCard,inlineHandleClick,pouupContent,handleMessageChange,bannerThemeResponse,setIsDownload }) => {

	const messageStyle = {
		whiteSpace: 'pre-wrap',
		color: `${data?.message?.color}`,
		fontSize:  `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	}
	// whiteSpace: 'pre-wrap',
	// color: `${data?.message?.color}`,
	// fontSize: `${data?.message?.size}px`,
	// fontWeight: `${data?.message?.fontWeight}`,
	// lineHeight: "1.5",
	// textAlign: `${data?.message?.textAlign}`,
	// fontFamily: `${data?.message?.fontFamily}`,
	// fontStyle: `${data?.message?.fontStyle}`,
	// textDecoration: `${data?.message?.textDecoration}`
	// const quoteStyle = {
	// 	backgroundColor: `#fff`,
	// }
	const quoteStyle = {
		backgroundColor: `${data?.message?.background}`,
	}
	const quoteBorder = data?.message?.borderImage?.length < 10 ? `linear-gradient(90deg, ${data?.message?.borderImage}, rgb(246, 255, 106) 28%, rgb(255, 255, 255) 51%, rgb(246, 246, 102) 69%, ${data?.message?.borderImage})` : `${data?.message?.borderImage}`;

	let showMessage = '';
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false
	});
	const [imageLoaded, setImageLoaded] = useState(false);
	const [showInstagramPost,setShowInstagramPost] = useState(false)
	const handleImageLoad = () => {
        setImageLoaded(true);
    };

	useEffect(() => {
        if (data.image?.backgroundFrame || backgroundFrame) {
            const img = new Image();
            img.onload = () => {
                setImageLoaded(true);
            };
            img.src = data.image?.backgroundFrame || backgroundFrame;
        }
    }, [data.image?.backgroundFrame || backgroundFrame]); // Re-run effect when imageUrl changes

	const design = (
		<>
			<div className="result_items quoteDesignOtr quoteDesign3" >
				{
					!shareImage && <Result_Overlay clickedTabs={clickedTabs} index={index} downloadText={downloadText} setDownloadText={setDownloadText} captureDivRef1={captureDivRef1} title={title} sendData={sendData} showMessage={showMessage} showTabs={showTabs} handleClick={handleClick} data={data} alert={alert} design={"QuoteDesign3"} setDownloadCard={setDownloadCard} setShowInstagramPost={setShowInstagramPost} bannerThemeResponse={bannerThemeResponse} setIsDownload={setIsDownload} />
				}
				{
					<div 
						ref={captureDivRef1} 
						className={`inner_result_data design_one `}
						style={{ backgroundColor: `${data?.div?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}
					>
						<div className={`editor-page edit-page-bg ${pouupContent === "background_image" && 'active'}`} onClick={()=>{inlineHandleClick("background_image")}}><img src={"image/edit-page/eidt-page-bg.png"} /></div>
						 {/* {!imageLoaded && <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
								<div class="skype-loader">
									<div class="dot">
										<div class="first"></div>
									</div>
									<div class="dot"></div>
									<div class="dot"></div>
									<div class="dot"></div>
								</div>
								<p>Please wait...</p>
							</div>} */}
						<div 
							className="edit-background-frame" 
							style={{ backgroundColor: `${data.image?.background}`,backgroundImage: `url(${data.image?.backgroundFrame || backgroundFrame})` }}
							onLoad={handleImageLoad}
						></div>
						<div 
							className="result-inner" 
							style={{ maxWidth: data?.div?.background ? '96%' : '', borderRadius: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderRadius}`, fontFamily: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.fontFamily}`, color: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.color}`, opacity: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.is_opaque === "yes" ? data?.div?.opacity : 'inherit'}`, borderColor: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderColor}` }}
						>
						 <div className="quote-result-inner">
						 <div className="quote3-icon">
						   <svg className="dubble-quote-icon" version="1.2" viewBox="0 0 450 273" fill={`${data?.message?.background}`}><path class="a" d="m206.9 103.5c0-57-46.2-103.3-103.3-103.3-57 0-103.3 46.3-103.3 103.3 0 58.5 48.9 106.7 109.7 103-4.1 19.8-14.3 39.4-24.1 54.6-4.5 7 3.7 15.1 10.6 10.5 57.9-38.7 110.4-99.3 110.4-168.1z"/><path class="a" d="m449.2 103.5c0-57-46.3-103.3-103.3-103.3-57.1 0-103.3 46.3-103.3 103.3 0 58.5 48.8 106.7 109.6 103-4.1 19.8-14.2 39.4-24 54.6-4.5 7 3.7 15.1 10.6 10.5 57.8-38.7 110.4-99.3 110.4-168.1z"/></svg>
						   <svg className="dubble-quote-icon-bg" version="1.2" viewBox="0 0 679 895" fill={`${data?.message?.color}`}>
							<path class="a" d="m678.2 339c0-187.1-151.6-338.7-338.6-338.7-187 0-338.7 151.6-338.7 338.7 0 191.5 160.2 349.7 359.5 337.6-13.4 64.9-46.7 128.9-78.9 178.9-14.7 22.9 12.2 49.4 34.8 34.3 189.6-126.9 361.9-325.4 361.9-550.8z"/></svg>
						  </div>
							<p style={messageStyle} className="template-message" contentEditable={pouupContent === "message_edit" && true} onBlur={handleMessageChange}>
							<div className={`editor-page edit-page-text ${pouupContent === "message_edit" && 'active'}`} onClick={()=>{inlineHandleClick("message_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
								{showMessage.message}</p>
				     	 </div>
				 		</div>
						<p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>

					</div>
				}
			</div>
		</>
	);
	return design;
}