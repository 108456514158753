import React, { useEffect } from "react";
import download from "../../../../landingpage/image/download_icon.png";
import save from "../../../../landingpage/image/save.png";
import { handleDownloadClick } from "../../../../landingpage/designTemplates/commonFunction";
import { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { savedTemplate, editTemplate, Footer, SocialLink } from "./common";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../landingpage/image/edit_icon_white.png";
import branding from "../../../../landingpage/image/refer-frnd.png";
import { Nav } from "react-bootstrap";



const BirthdayTemplate = ({ promptResponse, resultImage, formData, corporateUserInfo, showTemplateTitle, sendData, designType, category, occasion, prompt, savedData, isSavedTemplate, index, setMasonaryValue, setEditData, dispatch, multiformRequest, showFooterButton, titleColor, responseColor, backgroundImage, onlineImage, isEditPage, isPersoanlInfo, otherColor, titleFontFamily,
    messageFontFamily,
    otherFontFamily,
    fontSize,
    fontStyle,
    textAlignment,
    allFormData,
    isSavedEdit,
    logo,
    isDefault,
    company_information,
    setShowSocialMedia,
    showUploadImageActive,
    showCompanyInfoBtn,
    nameDesign="name-d1",
    nameStyle
}) => {
    // const templateData = JSON.parse(promptResponse);
    // console.log(formData, designType, category, occasion, "FOrmdTATAT", designType)
    // console.log(isSavedEdit, showUploadImageActive, 8888888,resultImage)
    const title_style = {
        font_family: titleFontFamily,
        font_size: fontSize?.titleFontSize,
        font_style: {
            italic: fontStyle?.titleFontStyle.italic,
            bold: fontStyle?.titleFontStyle.bold,
            underline: fontStyle?.titleFontStyle.underline
        },
        text_alignment: textAlignment?.titleTextAlign.textAlign,
        color: titleColor
    }
    const message_style = {
        font_family: messageFontFamily,
        font_size: fontSize?.messageFontSize,
        font_style: {
            italic: fontStyle?.messageFontStyle.italic,
            bold: fontStyle?.messageFontStyle.bold,
            underline: fontStyle?.messageFontStyle.underline
        },
        text_alignment: textAlignment?.messageTextAlign.textAlign,
        color: responseColor
    }
    const other_style = {
        font_family: otherFontFamily,
        font_size: fontSize?.otherFontSize,
        font_style: {
            italic: fontStyle?.otherFontStyle.italic,
            bold: fontStyle?.otherFontStyle.bold,
            underline: fontStyle?.otherFontStyle.underline
        },
        text_alignment: textAlignment?.otherTextAlign.textAlign,
        color: otherColor
    }
    const nameStyles = {
        fontSize: nameStyle?.fontSize?.nameFontSize,
        fontFamily: nameStyle?.nameFontFamily,
        color: nameStyle?.nameColor,
        fontWeight: nameStyle?.fontStyle?.nameFontStyle?.bold?.fontWeight,
        textDecoration: nameStyle?.fontStyle?.nameFontStyle?.underline?.textDecoration,
        fontStyle: nameStyle?.fontStyle?.nameFontStyle?.italic?.fontStyle,
        textAlign: nameStyle?.textAlignment?.nameTextAlign?.textAlign,
        textTransform: "capitalize",
        backgroundColor: (nameDesign === "name-d2" || nameDesign === "name-d3") ? nameStyle?.backgroundColor : "",
        borderColor: (nameDesign === "name-d4") ? nameStyle?.borderColor : "",
    }
    const Navigator = useNavigate();
    const message = promptResponse;
    let name = "";
    if (isSavedTemplate) {
        name = savedData?.name
    } else if (isSavedEdit) {
        name = allFormData.name
    }
    else {
        name = formData?.name
    }
    const captureDivRef1 = useRef(null);
    const alert = useAlert();
    const [downloadText, setDownloadText] = useState({
        text: "Download",
        disabled: false,
    });
    const [saveImage, setSaveImage] = useState({
        text: "Save",
        disabled: false,
    });
    const [persoanlInfo, setPersonalInfo] = useState(false);
    const [imageHeight, setImageHeight] = useState(true);
    // const [showFooterButton,setShowFooterButton] = useState
    // console.log(persoanlInfo, "Personalinfo", isPersoanlInfo)
    useEffect(() => {
        if (isSavedTemplate) {
            // console.log(savedData?.persoanlInfo)
            setPersonalInfo(savedData?.persoanlInfo);
        }
    }, [])
    useEffect(() => {
        if (company_information === true || company_information === false) {
            setPersonalInfo(company_information);
        }
    }, [company_information])

    // useEffect(() => {
    //     if (company_information === true || company_information === false) {
    //         setShowSocialMedia(persoanlInfo);
    //     }
    // }, [persoanlInfo])
    // useEffect(()=>{
    //     if(isPersoanlInfo !=="no"){
    //       setPersonalInfo(isPersoanlInfo)
    //     }
    //   },[isPersoanlInfo])

    // const background_image = {

    //     if(isSavedTemplate){
    //         this.if()
    //     }
    //     // backgroundImage: isSavedTemplate ? `url(${savedData?.backzground_image})` : `url(${onlineImage})`,
    //     // backgroundSize:"cover"
    // }
    // console.log("testing",persoanlInfo)
    let background_image = null;
    if (isSavedTemplate) {
        if (savedData?.background_image) {
            background_image = {
                backgroundImage: `url(${savedData?.background_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    } else {
        if (backgroundImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    if (isSavedEdit) {
        if (onlineImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    // const imageUrl = isDefault ? resultImage : allFormData?.profile_pic?.includes("storage")
    //     ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}`
    //     : allFormData?.profile_pic || resultImage && URL.createObjectURL(resultImage) || `${process.env.REACT_APP_URL_LOCAL}/${savedData?.profile_pic}`;

    // useEffect(() => {
    //     if (imageUrl) {
    //         const backgroundImage = new Image();
    //         backgroundImage.src = imageUrl;
    //         backgroundImage.onload = () => {
    //             if (backgroundImage.naturalHeight > backgroundImage.naturalWidth) {
    //                 setImageHeight(true)
    //             } else {
    //                 setImageHeight(false)
    //             }
    //         };
    //     }
    // }, [imageUrl]);

    const design = (
        <>
            <div className="business-templates-item template1" ref={captureDivRef1} style={background_image}>
                <div className="first-temp-head">
                    <img className="temp-logo" id="qqq" src={`${isDefault ? logo : `${process.env.REACT_APP_URL_LOCAL}/${corporateUserInfo.image}`}`} alt="branding" />
                    {persoanlInfo && <div className="temp5-call"><p>Call us now</p> <a className="template-call6" href="#"><i class="fa-solid fa-phone"></i><span>{corporateUserInfo.phone_number}</span></a></div>}
                </div>
                <div className="first-temp-mid">
                    <div className="first-temp-mid-left">
                        <h2 id="template-heading" style={{ color: `${titleColor}`, fontFamily: `${titleFontFamily}`, fontSize: `${fontSize?.titleFontSize}`, fontWeight: `${fontStyle?.titleFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.titleFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.titleFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.titleTextAlign.textAlign}` }}>{showTemplateTitle}</h2>
                        <p id="template-message" style={{ whiteSpace: 'pre-wrap', color: `${responseColor}`, fontFamily: `${messageFontFamily}`, fontSize: `${fontSize?.messageFontSize}`, fontWeight: `${fontStyle?.messageFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.messageFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.messageFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.messageTextAlign.textAlign}` }}>{promptResponse}</p>
                        {/* <p>"Congratulations on your work anniversary! Here's to another year of success and growth."</p> */}
                    </div>
                    <div className="first-temp-mid-right-otr">
                        <div className="first-temp-mid-right" id="croped-size-el">
                            {
                                isSavedTemplate ? (
                                    // !backgroundImage ? (
                                    // <div className="employee-pic-bg div-image" style={{ backgroundImage: `url(${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic})` }}></div>
                                    <img className="tamp6-img 11" src={`${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                    // ) : (
                                    //     <div className="employee-pic-bg div-image" style={{ backgroundImage: `url(${savedData.profile_pic})` }}></div>
                                    // )


                                ) : (
                                    !isSavedEdit ? (
                                        // <div className="employee-pic-bg div-image" style={{ backgroundImage: `url(${resultImage && URL.createObjectURL(resultImage)})` }}></div>
                                        isDefault ? (
                                            <img className="tamp6-img 22" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                        ) : (
                                            showUploadImageActive?.cropedImage ? (
                                                <img className="tamp6-img 34" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                            ):(
                                                <img className="tamp6-img 33" src={`${resultImage && URL.createObjectURL(resultImage)}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                            )
                                            
                                        )
                                    ) : (
                                        <>
                                            {
                                                showUploadImageActive.uploadedImage ? (
                                                    <img className="tamp6-img 44" src={`${resultImage && URL.createObjectURL(resultImage)}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                ) : (
                                                    showUploadImageActive?.cropedImage ? (
                                                        <img className="tamp6-img 44"
                                                        src={`${resultImage}`}
                                                        alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                                    ):(
                                                        <img className="tamp6-img 44"
                                                        src={`${allFormData?.profile_pic.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}` : allFormData?.profile_pic}`}
                                                        alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                    )
                                                    
                                                )
                                            }


                                        </>
                                    )
                                )
                            }
                            {/* <img className="employee-pic" src={URL.createObjectURL(resultImage)} alt="branding" /> */}
                        </div>
                        <h4 class={`ribbon temp1-name name-d1 name-dsgn ${nameDesign === "name-d1" && 'active'}`}><span class="ribbon-content" style={nameStyles}>{name}</span></h4>
                        <h4 class={`name-d2 name-dsgn ${nameDesign === "name-d2" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d3 name-dsgn ${nameDesign === "name-d3" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d4 name-dsgn ${nameDesign === "name-d4" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d5 name-dsgn ${nameDesign === "name-d5" && 'active'}`} style={nameStyles}>{name}</h4>
                    </div>
                </div>
                <div className="first-temp-footer">
                    {
                        persoanlInfo &&
                        <SocialLink
                            otherColor={otherColor}
                            otherFontFamily={otherFontFamily}
                            fontStyle={fontStyle}
                            corporateUserInfo={corporateUserInfo}
                            textAlignment={textAlignment}
                            fontSize={fontSize}
                        />
                    }
                </div>
                {
                    !showFooterButton &&
                    <Footer
                        index={index}
                        persoanlInfo={persoanlInfo}
                        setPersonalInfo={setPersonalInfo}
                        setMasonaryValue={setMasonaryValue}
                        downloadText={downloadText}
                        handleDownloadClick={handleDownloadClick}
                        setDownloadText={setDownloadText}
                        captureDivRef1={captureDivRef1}
                        showTemplateTitle={showTemplateTitle}
                        sendData={sendData}
                        alert={alert}
                        savedTemplate={savedTemplate}
                        isSavedTemplate={isSavedTemplate}
                        saveImage={saveImage}
                        formData={formData}
                        designType={designType}
                        category={category}
                        occasion={occasion}
                        promptResponse={promptResponse}
                        corporateUserInfo={corporateUserInfo}
                        prompt={prompt}
                        setSaveImage={setSaveImage}
                        backgroundImage={backgroundImage}
                        onlineImage={onlineImage}
                        title_style={title_style}
                        message_style={message_style}
                        other_style={other_style}
                        isSavedEdit={isSavedEdit}
                        allFormData={allFormData}
                        save={save}
                        isEditPage={isEditPage}
                        editTemplate={editTemplate}
                        Navigator={Navigator}
                        editIcon={editIcon}
                        download={download}
                        setEditData={setEditData}
                        dispatch={dispatch}
                        showCompanyInfoBtn={showCompanyInfoBtn}
                    // scale={1}
                    />
                }
                <p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
            </div>

        </>
    );
    return design
}

export default React.memo(BirthdayTemplate);