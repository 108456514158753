
const findoutgreeting = process.env.REACT_APP_URL_FRONT_TEST+"/image/Find-out-greeting.webp";
const findoutquote = process.env.REACT_APP_URL_FRONT_TEST+"/image/homepage/find-out-quote.webp";
const findoutpoem = process.env.REACT_APP_URL_FRONT_TEST+"/image/Find-out-poem.webp";
const findoutjoke = process.env.REACT_APP_URL_FRONT_TEST+"/image/homepage/find-out-joke.webp";

export const ShowCategoryDesign = ({bannerThemeResponse}) => {
    return (
        <>
            <section className="business-using-varnz home-using">
                <div className="cstm-container">
                    <div className="business-features-inner">
                        <div className="heading_portion section-header">
                            <h2 className="cstm-h2">Find Out What Else You Can Design <span className="heading-highlight" 
                            style={{color:bannerThemeResponse?.button_main_color}}
                            >Using Varnz</span></h2>
                            <p>Choose a card design, upload photos, and share with your staff or loved ones</p>
                        </div>
                        <div className="home-using-list">
                            <ul>
                                <li class="wow fadeInUp greeting-using" data-wow-duration="600ms" data-wow-delay="600ms">
                                    <a href="/ai-greeting-card-generator"><img className="" src={findoutgreeting} alt="ai greeting card generator" /><span className="home-using-title">Greetings</span></a>
                                </li>
                                <li class="wow fadeInUp quote-using" data-wow-duration="700ms" data-wow-delay="700ms">
                                    <a href="/ai-quote-generator"><img className="" src={findoutquote} alt="ai quote generator" /><span className="home-using-title">Quotes</span></a>
                                </li>
                                <li class="wow fadeInUp poem-using" data-wow-duration="800ms" data-wow-delay="800ms">
                                    <a href="/ai-poem-generator"><img className="" src={findoutpoem} alt="ai poem generator" /><span className="home-using-title">Poem</span></a>
                                </li>
                                <li class="wow fadeInUp jokes-using" data-wow-duration="1000ms" data-wow-delay="1000ms">
                                    <a href="/ai-joke-generator"><img className="" src={findoutjoke} alt="ai joke generator" /><span className="home-using-title">Jokes</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}