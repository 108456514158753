import React from "react";
import template19bg1 from "../../../../landingpage/image/teamplate19-bg1.png";
import template19bg2 from "../../../../landingpage/image/teamplate19-bg2.png";
import template19bg3 from "../../../../landingpage/image/teamplate19-bg3.png";
import template15shap from "../../../../landingpage/image/template15-shap.png";
import { handleDownloadClick } from "../../../../landingpage/designTemplates/commonFunction";
import { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import save from "../../../../landingpage/image/save.png";
import { savedTemplate, editTemplate, Footer, SocialLink } from "./common";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import download from "../../../../landingpage/image/download_icon.png";
import editIcon from "../../../../landingpage/image/edit_icon_white.png";
import branding from "../../../../landingpage/image/refer-frnd.png";


const BirthdayTemplate = ({ promptResponse, resultImage, formData, corporateUserInfo, showTemplateTitle, sendData, designType, category, occasion, prompt, savedData, isSavedTemplate, index, setMasonaryValue, setEditData, dispatch, multiformRequest, showFooterButton, titleColor, responseColor, backgroundImage, onlineImage, isEditPage, isPersoanlInfo, otherColor, titleFontFamily,
    messageFontFamily,
    otherFontFamily,
    fontSize,
    fontStyle,
    textAlignment, allFormData,
    isSavedEdit, logo,
    isDefault,
    company_information,
    setShowSocialMedia,
    showUploadImageActive,
    showCompanyInfoBtn,
    nameDesign="name-d5",
    nameStyle
}) => {

    const title_style = {
        font_family: titleFontFamily,
        font_size: fontSize?.titleFontSize,
        font_style: {
            italic: fontStyle?.titleFontStyle.italic,
            bold: fontStyle?.titleFontStyle.bold,
            underline: fontStyle?.titleFontStyle.underline
        },
        text_alignment: textAlignment?.titleTextAlign.textAlign,
        color: titleColor
    }
    const message_style = {
        font_family: messageFontFamily,
        font_size: fontSize?.messageFontSize,
        font_style: {
            italic: fontStyle?.messageFontStyle.italic,
            bold: fontStyle?.messageFontStyle.bold,
            underline: fontStyle?.messageFontStyle.underline
        },
        text_alignment: textAlignment?.messageTextAlign.textAlign,
        color: responseColor
    }
    const other_style = {
        font_family: otherFontFamily,
        font_size: fontSize?.otherFontSize,
        font_style: {
            italic: fontStyle?.otherFontStyle.italic,
            bold: fontStyle?.otherFontStyle.bold,
            underline: fontStyle?.otherFontStyle.underline
        },
        text_alignment: textAlignment?.otherTextAlign.textAlign,
        color: otherColor
    }
    const nameStyles = {
        fontSize: nameStyle?.fontSize?.nameFontSize,
        fontFamily: nameStyle?.nameFontFamily,
        color: nameStyle?.nameColor,
        fontWeight: nameStyle?.fontStyle?.nameFontStyle?.bold?.fontWeight,
        textDecoration: nameStyle?.fontStyle?.nameFontStyle?.underline?.textDecoration,
        fontStyle: nameStyle?.fontStyle?.nameFontStyle?.italic?.fontStyle,
        textAlign: nameStyle?.textAlignment?.nameTextAlign?.textAlign,
        textTransform: "capitalize",
        backgroundColor: (nameDesign === "name-d2" || nameDesign === "name-d3") ? nameStyle?.backgroundColor : "",
        borderColor: (nameDesign === "name-d4") ? nameStyle?.borderColor : "",
    }
    const captureDivRef1 = useRef(null);
    const alert = useAlert();
    const Navigator = useNavigate();
    const [downloadText, setDownloadText] = useState({
        text: "Download",
        disabled: false,
    });
    function DateFormatter({ inputDate }) {
        const formattedDate = moment(new Date(), "M/D/YYYY").format("Do MMMM YYYY");

        return formattedDate;
    }
    const [saveImage, setSaveImage] = useState({
        text: "Save",
        disabled: false,
    });
    let name = "";
    if (isSavedTemplate) {
        name = savedData?.name
    } else if (isSavedEdit) {
        name = allFormData.name
    } else {
        name = formData?.name
    }
    const [persoanlInfo, setPersonalInfo] = useState(false);
    const [imageHeight, setImageHeight] = useState(true);

    useEffect(() => {
        if (isSavedTemplate) {
            // console.log(savedData?.persoanlInfo)
            setPersonalInfo(savedData?.persoanlInfo);
        }
    }, [])
    useEffect(() => {
        if (company_information === true || company_information === false) {
            setPersonalInfo(company_information);
        }
    }, [company_information])
    // useEffect(() => {
    //     if (company_information === true || company_information === false) {
    //         setShowSocialMedia(persoanlInfo);
    //     }
    // }, [persoanlInfo])
    let background_image = null;
    if (isSavedTemplate) {
        if (savedData?.background_image) {
            background_image = {
                backgroundImage: `url(${savedData?.background_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    } else {
        if (backgroundImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    if (isSavedEdit) {
        if (onlineImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    // const imageUrl = isDefault ? resultImage : allFormData?.profile_pic?.includes("storage")
    //     ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}`
    //     : allFormData?.profile_pic || resultImage && URL.createObjectURL(resultImage) || `${process.env.REACT_APP_URL_LOCAL}/${savedData?.profile_pic}`;

    // useEffect(() => {
    //     if (imageUrl) {
    //         const backgroundImage = new Image();
    //         backgroundImage.src = imageUrl;
    //         backgroundImage.onload = () => {
    //             if (backgroundImage.naturalHeight >= backgroundImage.naturalWidth) {
    //                 setImageHeight(true)
    //             } else {
    //                 setImageHeight(false)
    //             }
    //         };
    //     }
    // }, [imageUrl]);


    const design = (
        <>
            <div className="business-templates-item template19" ref={captureDivRef1} style={background_image}>
                <div className="first-temp-head template5-header">
                    <img className="temp-logo" src={`${isDefault ? logo : `${process.env.REACT_APP_URL_LOCAL}/${corporateUserInfo.image}`}`} alt="branding" />
                </div>
                <div className="template8-data">
                    <div className="tamp18-image-otr">
                        <img className="template19bg1" src={template19bg1} alt="template19bg1" />
                        <img className="template19bg2" src={template19bg2} alt="template19bg2" />
                        <img className="template19bg3" src={template19bg3} alt="template19bg3" />
                        <div className="tamp19-image">
                            <div className="tamp9-image-inr" id="croped-size-el">
                                {
                                    isSavedTemplate ? (
                                        <img className="tamp-img" src={`${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                    ) : (
                                        !isSavedEdit ? (
                                            isDefault ? (
                                                <img className="tamp-img" src={resultImage} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                            ) : (
                                                showUploadImageActive?.cropedImage ? (
                                                    <img className="tamp-img" src={resultImage} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                                ):(
                                                    <img className="tamp-img" src={resultImage && URL.createObjectURL(resultImage)} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                )
                                                
                                            )
                                        ) : (
                                            showUploadImageActive.uploadedImage ? (
                                                <img className="tamp-img" src={resultImage && URL.createObjectURL(resultImage)} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                            ) : (
                                                showUploadImageActive?.cropedImage ? (
                                                    <img className="tamp-img" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                                ):(
                                                    <img className="tamp-img" src={`${allFormData?.profile_pic.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}` : allFormData?.profile_pic}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                )
                                                
                                            )
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>

                 <div className="tamp19-text">
                  <div className="tamp19-heading">
                     <h4 class={`ribbon temp1-name name-d1 name-dsgn ${nameDesign === "name-d1" && 'active'}`}><span class="ribbon-content" style={nameStyles}>{name}</span></h4>
                     <h4 class={`name-d2 name-dsgn ${nameDesign === "name-d2" && 'active'}`} style={nameStyles}>{name}</h4>
                     <h4 class={`name-d3 name-dsgn ${nameDesign === "name-d3" && 'active'}`} style={nameStyles}>{name}</h4>
                     <h4 class={`name-d4 name-dsgn ${nameDesign === "name-d4" && 'active'}`} style={nameStyles}>{name}</h4>
                     <h3 class={`name-d5 name-dsgn template18-name ${nameDesign === "name-d5" && 'active'}`} style={nameStyles}>{name}</h3>
                    </div>
                    <h2 id="template-heading" style={{ color: `${titleColor}`, fontFamily: `${titleFontFamily}`, fontSize: `${fontSize?.titleFontSize}`, fontWeight: `${fontStyle?.titleFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.titleFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.titleFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.titleTextAlign.textAlign}` }}>{showTemplateTitle}</h2>
                    <p style={{ whiteSpace: 'pre-wrap', color: `${responseColor}`, fontFamily: `${messageFontFamily}`, fontSize: `${fontSize?.messageFontSize}`, fontWeight: `${fontStyle?.messageFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.messageFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.messageFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.messageTextAlign.textAlign}` }}>{promptResponse}</p>
                    </div>
                </div>
                {persoanlInfo && <a className="template-call6 template-call-us-now" href="#"><i class="fa-solid fa-phone"></i><span>{corporateUserInfo.phone_number}</span><span className="call-us">Call us now</span></a>}
                <div className="first-temp-footer">
                    {
                        persoanlInfo &&
                        <SocialLink
                            otherColor={otherColor}
                            otherFontFamily={otherFontFamily}
                            fontStyle={fontStyle}
                            corporateUserInfo={corporateUserInfo}
                            textAlignment={textAlignment}
                            fontSize={fontSize}
                            className="brdr-ftr-design"
                        />
                    }
                </div>
                {
                    !showFooterButton &&
                    <Footer
                        index={index}
                        persoanlInfo={persoanlInfo}
                        setPersonalInfo={setPersonalInfo}
                        setMasonaryValue={setMasonaryValue}
                        downloadText={downloadText}
                        handleDownloadClick={handleDownloadClick}
                        setDownloadText={setDownloadText}
                        captureDivRef1={captureDivRef1}
                        showTemplateTitle={showTemplateTitle}
                        sendData={sendData}
                        alert={alert}
                        savedTemplate={savedTemplate}
                        isSavedTemplate={isSavedTemplate}
                        saveImage={saveImage}
                        formData={formData}
                        designType={designType}
                        category={category}
                        occasion={occasion}
                        promptResponse={promptResponse}
                        corporateUserInfo={corporateUserInfo}
                        prompt={prompt}
                        setSaveImage={setSaveImage}
                        backgroundImage={backgroundImage}
                        onlineImage={onlineImage}
                        title_style={title_style}
                        message_style={message_style}
                        other_style={other_style}
                        isSavedEdit={isSavedEdit}
                        allFormData={allFormData}
                        save={save}
                        isEditPage={isEditPage}
                        editTemplate={editTemplate}
                        Navigator={Navigator}
                        editIcon={editIcon}
                        download={download}
                        setEditData={setEditData}
                        dispatch={dispatch}
                        showCompanyInfoBtn={showCompanyInfoBtn}
                    />
                }
                {persoanlInfo && <div className="template3-footer new-btm-ftr">
                    <div className="social-temp-link address-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                        <i>
                            <svg class="address-icon" fill="fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="15px" height="15px" viewBox="0 0 395.71 395.71" xmlSpace="preserve">
                                <g>
                                    <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.951-6.738c5.305-7.211,129.896-177.501,129.896-250.388
                            C335.168,61.609,273.569,0,197.849,0z M197.849,199.571c-27.633,0-50.143-22.51-50.143-50.143s22.51-50.143,50.143-50.143
                            s50.143,22.51,50.143,50.143S225.482,199.571,197.849,199.571z" />
                                </g>
                            </svg>
                        </i>
                        <p id="template-message" style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }}>{corporateUserInfo.company_address}</p>
                    </div>
                </div>
                }
                <p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
            </div>
        </>
    );
    return design
}

export default React.memo(BirthdayTemplate);