const PROFILE_REQUEST = "PROFILE_REQUEST";
const PROFILE_SUCCESS = "PROFILE_SUCCESS";
const PROFILE_ERROR = "PROFILE_ERROR";
const SOCIAL_LOGIN_SUCESS= "SOCIAL_LOGIN_SUCESS"
const SOCIAL_LOGIN_SUCESS_ERROR= "SOCIAL_LOGIN_SUCESS_ERROR"
export {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  SOCIAL_LOGIN_SUCESS,
  SOCIAL_LOGIN_SUCESS_ERROR
}
