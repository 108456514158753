const consoleError = console.error;

console.error = (...args) => {
  if (
    args.length > 0 &&
    typeof args[0] === 'string' &&
    args[0].startsWith('Warning:')
  ) {
    // Ignore specific warning messages by checking the content
    // You can customize this condition to ignore specific warnings
    return;
  }

  consoleError.apply(console, args);
};
