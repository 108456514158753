import React, { useEffect } from 'react';
export const Sitemap = () => {
  

  return (
    <>
      <div>
        <pre>{`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">

<url>
      <loc>https://varnz.ai/</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-international-nurses-day-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-international-day-of-families-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-buddha-purnima-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-national-brother-day-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-africa-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-no-tobacco-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-bicycle-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-environment-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-national-day-of-sweden-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-day-against-child-labour-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-blood-donor-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-yoga-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-music-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-doctor-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-puri-rath-yatra-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-guru-purnima-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-ganesh-chaturthi-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-nag-panchami-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-raksha-bandhan-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-janmashtami-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-dahi-handi-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-canada-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-usa-independence-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-dalai-lama-birthday-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-islamic-new-year-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-muharram-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-youth-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-teacher-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-laughter-day-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-no-diet-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-world-athletics-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-lord-parshuram-jayanti-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-akshaya-tritiya-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-national-technology-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/blog/international-day-of-families-2024</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-labour-day-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/holi</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/lohri</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/mother-day</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/father-day</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/business</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-greeting-cards</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-quote-generator</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-poem-generator</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
<url>
      <loc>https://varnz.ai/ai-generated-jokes</loc>
      <lastmod>2024-05-16</lastmod>
      <changefreq>weekly</changefreq>
      <priority>1</priority>
</url>
</urlset>`}</pre>
      </div>

    </>
  );
};
