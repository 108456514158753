import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Result_Overlay } from './commonFunction';
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from 'react-masonry-component';
import height from "./commonFunction/height";
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }

const backgroundFrame = "/frame/background/template-quote.jpg";


export const PoemDesign8 = ({ data, templateData, title, index, handleClick, clickedTabs, sendData, showTabs, message, shareImage, color, defaultImage, overlay }) => {

	const messageStyle = {
		whiteSpace: 'pre-wrap',
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	}

	
	const titleStyle = {
		color: `${data?.heading?.color}`,
		fontStyle: `${data?.heading?.fontStyle}`,
		fontSize: `${data?.heading?.size}px`,
		fontWeight: `${data?.heading?.fontWeight}`,
		fontFamily: `${data?.heading?.fontFamily}`,
		textDecoration: `${data?.heading?.textDecoration}`,
		textAlign: `${data?.heading?.textAlign}`
	}

	const quoteStyle = {
		backgroundColor: `#0000`,
	}
	const quoteBorder = data?.message?.borderImage?.length < 10 ? `linear-gradient(90deg, ${data?.message?.borderImage}, rgb(246, 255, 106) 28%, rgb(255, 255, 255) 51%, rgb(246, 246, 102) 69%, ${data?.message?.borderImage})` : `${data?.message?.borderImage}`;
	// const messageStyle = {
	// 	whiteSpace: 'pre-wrap',
	// 	color: `${data?.message?.color}`,
	// 	fontSize: `${data?.message?.size}px`,
	// 	fontWeight: `${data?.message?.fontWeight}`,
	// 	lineHeight: "1.5",
	// 	textAlign: `${data?.message?.textAlign}`,
	// 	fontFamily: `${data?.message?.fontFamily}`,
	// 	fontStyle: `${data?.message?.fontStyle}`,
	// 	textDecoration: `${data?.message?.textDecoration}`
	// }

	let showMessage = '';
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false
	});
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = () => {
        setImageLoaded(true);
    };

	const design = (
		<>
			<div className="result_items peom-result poemDesign8">
				{
					!shareImage && <Result_Overlay clickedTabs={clickedTabs} index={index} downloadText={downloadText} setDownloadText={setDownloadText} captureDivRef1={captureDivRef1} title={title} sendData={sendData} showMessage={showMessage} showTabs={showTabs} handleClick={handleClick} data={data} alert={alert} design={"PoemDesign8"} />
				}
				{
					<div ref={captureDivRef1} className='inner_result_data design_one' style={{ backgroundColor: `${data?.div?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}>
						<div className="edit-background-frame" style={{ backgroundImage: `url(${data.image?.backgroundFrame || backgroundFrame})` }}></div>
						<div className="result-inner" style={{ maxWidth: data?.div?.background ? '100%' : '', borderRadius: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderRadius}`, padding: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.padding}`, fontFamily: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.fontFamily}`, color: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.color}`, opacity: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.is_opaque === "yes" ? data?.div?.opacity : 'inherit'}`, borderColor: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderColor}`,backgroundImage:`${quoteBorder}` }}>
						 <div className="poem-result-inner" style={quoteStyle}>
					     	<h3 style={titleStyle} className="template-heading"> {title === "Othersa" ? null : showMessage.heading}	</h3>
							<p style={messageStyle} className="template-message">{showMessage.message}</p>
				     	 </div>
				 		</div>
						<p className="branding-logo"><img src={branding} alt="branding" />Created with Varnz.ai</p>

					</div>
				}
			</div>
		</>
	);
	return design;
}