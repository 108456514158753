import React from 'react'
import branding from "../multiStepForm/image/branding.png";
import profileimg from "../multiStepForm/image/profile.jpg";
import LogoutButton from '../signup/Logout';
import { Navigate, useNavigate } from 'react-router-dom';
import AvatarWithLetter from '../../../commonComponents/avtar';
import { useState } from 'react';

export default function Businessheader({corporateUser,setAlertMessage}) {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const userEmail = JSON.parse(localStorage.getItem("guitar_user"));
  const [activeAI,setActiveAI] = useState(false);

  // console.log(userData,"23232323")
  const Navigator = useNavigate();
  const [show1, setShow1] = useState(false);

  const handleClick = (data)=>{
    if(corporateUser >= 0){
      setAlertMessage("")
      if(corporateUser > 0){
        
        Navigator(`/${data}`);
      }else{
        setTimeout(()=>{
          setAlertMessage("In order to begin crafting greetings for your team members, kindly provide the necessary business information.")
        },100)
      }
    }else{
      Navigator(`/${data}`);
    }
  }
  const navigate = useNavigate();
  const NavigateTool = () => {
    navigate('/tools');
  }

  return (
    <div className='business-header imagination-new-header'>
     <div className='cstm-container'>
      <div className='busnes-header-inner'> 
         <a href="/" className="business-logo"><img src={branding} alt="varnz logo" /></a>
         <div className="new-header-btns">
         <a href="/tools" className="brder-btn upgrade-btn go-to-tool-btn"><i class="fas fa-filter"></i>Go to tool</a>
         <div className="upgrade-otr-btn">
                <a
                  href="#"
                  className="brder-btn upgrade-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow1(true);
                  }}
                ><i class="fas fa-crown"></i>Upgrade</a>
                <p
                  href="#"
                  className=" credits-btn"><i class="fas fa-coins"></i>Credits</p>
              </div>
           {/* <a href="#" onClick={()=>handleClick("multiform")} className="orange-btn cstm-btn"><i class="far fa-comment-smile"></i>Wish Someone</a>
           <a href="/chat" className="blue-btn cstm-btn"><i className="far fa-repeat-alt"></i>Switch to Personal</a> */}
           <div className='business-header-user'>
            <div className='business-acount'
              onClick={()=>{setActiveAI(!activeAI)}}
            ><i class="fa-regular fa-user"></i>My Account<i class="far fa-angle-down"></i></div>
            <div className={`business-acount-dropdown ${activeAI && 'active-ai-page'}`}>
              <div className='user-login-info'>
                {
                  userData?.data?.image_path ? (
                    <img src={`${process.env.REACT_APP_URL_LOCAL}/${userData?.data?.image_path}`} alt="branding" />
                  ):(
                    userData?.data?.name ? (
                      <AvatarWithLetter  name={userData?.data?.name} imageUrl="path/to/ss.jpg"  />
                    ):(
                      <AvatarWithLetter  name={userEmail?.email} imageUrl="path/to/ss.jpg"  />
                    )
                  )
                }
                <div className='user-login-info-right'>
                  <h4>{userData?.data?.name}</h4>
                  <p>{userData?.data.email || userEmail?.email}</p>
                </div>
              </div>
             <ul>
              <li className='mob-non' onClick={NavigateTool}><a href="#" className=""><i class="fas fa-filter"></i>Go to tool</a></li>
              <li onClick={()=>handleClick("settings")}><a href="#" className=""><i class="far fa-user-edit"></i>Edit information</a></li>
              <li onClick={()=>handleClick("multiform")} className="d-none"><a href="#" className=""><i class="far fa-comment-smile"></i>Wish Someone</a></li>
              <li onClick={()=>handleClick("template-history")}><a href="#" className=""><i class="far fa-heart"></i>History</a></li>
              {/* <li onClick={()=>handleClick("default-card")}><a href="#" className=""><i class="far fa-heart"></i>Default Card</a></li> */}
              <li><LogoutButton /></li>
             </ul>
             </div>
           </div>
         </div>
      </div>
     </div>
    </div>
  )
}
