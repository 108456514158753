import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,SOCIAL_LOGIN_SUCESS,SOCIAL_LOGIN_SUCESS_ERROR
} from "./Profile.state";
import axios from "axios";
axios.defaults.baseURL = "http://localhost:3434";

const profileRequest = (formdata)=>{
  return async (dispatch)=>{
    try {
      dispatch({
        type: PROFILE_SUCCESS,
        payload: formdata
      });
    }
    catch(err)
    {
      dispatch({
        type: PROFILE_ERROR,
        error: err.response.data
      });
    }
  }
}

const SocialLoginAction = (formdata)=>{
  console.log(formdata,'formdataformdataformdata')
  return async (dispatch)=>{
    try {
      dispatch({
        type: SOCIAL_LOGIN_SUCESS,
        payload: formdata
      });
    }
    catch(err)
    {
      dispatch({
        type: SOCIAL_LOGIN_SUCESS_ERROR,
        error: err
      });
    }
  }
}

export {
  profileRequest,
  SocialLoginAction
}
