import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useAlert } from 'react-alert';
import Cropper from 'react-easy-crop';

const CropImage = ({image,cropedShape,cropedSize,showCropImage,setShowCropImage,cropedImage,setCropedImage}) => {
    // crop contant
    const alert = useAlert();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // const [cropedImage, setCropedImage] = useState({
    //     isCroped: false,
    //     image: ""
    // });
    // const [cropSize, setCropSize] = useState({
    //     width: "",
    //     height: ""
    // });
    const [cropText,setCropText] = useState({
        text: "Apply",
        disabled: false
    });

    // crop image function
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const showCroppedImage = async () => {
        try {
            setCropText({
                text: "Please wait",
                disabled: true
            })
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            );
            setShowCropImage(false);
            alert.success("Image Croped Successfully!", {
                timeout: 3000,
                position: "top right"
            })
            setCropedImage({
                isCroped: true,
                image: croppedImage
            });
            setCropText({
                text: "Apply",
                disabled: false
            })
        } catch (e) {
            console.error(e)
        }
    }
    const getCroppedImg = async (
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const rotRad = getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As Base64 string
        // return croppedCanvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            croppedCanvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        })
    }
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })
    const getRadianAngle = (degreeValue) => {
        return (degreeValue * Math.PI) / 180
    }
    const rotateSize = (width, height, rotation) => {
        const rotRad = getRadianAngle(rotation)
        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    // const getCropedSize = () => {
    //     const el = document.getElementById("croped-size-el");
    //     if (el) {
    //         const width = window.getComputedStyle(el).width;
    //         const height = window.getComputedStyle(el).height;
    //         setCropSize({
    //             width: parseInt(width),
    //             height: parseInt(height)
    //         });
    //     }
    // }

    const design = (
        <>
            <Modal
                className="how-work-popup how-video quote-video-popup edit-page-image-crop"
                show={showCropImage}
                onHide={() => { setShowCropImage(false) }}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-crop-main">
                        <div >
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1 / 1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                cropShape={cropedShape}
                                cropSize={{ width: cropedSize?.width, height: cropedSize?.height }}
                            />
                        </div>

                    </div>
                    <div className="edit-crop-btn">
                        <button
                            onClick={() => {

                                showCroppedImage();
                            }
                            }
                            variant="contained"
                            color="primary"
                            style={{ zIndex: 2, position: "relative" }}
                            disabled={cropText.disabled}
                        >
                            {cropText.text}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
    return design;
}

export default CropImage;