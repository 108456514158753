import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Result_Overlay } from './commonFunction';
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from 'react-masonry-component';
import height from "./commonFunction/height";
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }

const backgroundFrame = "/image/joke/satpal-bg.jpg";
const pankajtripathi = "/image/joke/satpal.jpg";


export const BirthdayDesign8 = ({ data, templateData, title, index, handleClick, clickedTabs, sendData, showTabs, message, shareImage, color, defaultImage, overlay,setDownloadCard, inlineHandleClick,pouupContent,handleMessageChange }) => {

	const messageStyle = {
		whiteSpace: 'pre-wrap',
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	}

	const quoteStyle = {
		backgroundColor: `${data?.message?.background}`,
	}
	const quoteBorder = data?.message?.borderImage?.length < 10 ? `linear-gradient(90deg, ${data?.message?.borderImage}, rgb(246, 255, 106) 28%, rgb(255, 255, 255) 51%, rgb(246, 246, 102) 69%, ${data?.message?.borderImage})` : `${data?.message?.borderImage}`;
	// const messageStyle = {
	// 	whiteSpace: 'pre-wrap',
	// 	color: `${data?.message?.color}`,
	// 	fontSize: `${data?.message?.size}px`,
	// 	fontWeight: `${data?.message?.fontWeight}`,
	// 	lineHeight: "1.5",
	// 	textAlign: `${data?.message?.textAlign}`,
	// 	fontFamily: `${data?.message?.fontFamily}`,
	// 	fontStyle: `${data?.message?.fontStyle}`,
	// 	textDecoration: `${data?.message?.textDecoration}`
	// }

	let showMessage = '';
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false
	});
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = () => {
        setImageLoaded(true);
    };

	useEffect(() => {
        if (data.image?.backgroundFrame || backgroundFrame) {
            const img = new Image();
            img.onload = () => {
                setImageLoaded(true);
            };
            img.src = data.image?.backgroundFrame || backgroundFrame;
        }
    }, [data.image?.backgroundFrame || backgroundFrame]); // Re-run effect when imageUrl changes

	const design = (
		<>
			<div className="result_items jokeDesignOtr jokeDesign4 jokeDesign8" >
				{
					!shareImage && <Result_Overlay clickedTabs={clickedTabs} index={index} downloadText={downloadText} setDownloadText={setDownloadText} captureDivRef1={captureDivRef1} title={title} sendData={sendData} showMessage={showMessage} showTabs={showTabs} handleClick={handleClick} data={data} alert={alert} design={"QuoteDesign1"} setDownloadCard={setDownloadCard} />
				}
				{
					<div ref={captureDivRef1} className='inner_result_data design_one' style={{ backgroundColor: `${data?.image?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}>
						<div className={`editor-page edit-page-bg ${pouupContent === "background_image" && 'active'}`} onClick={()=>{inlineHandleClick("background_image")}}><img src={"image/edit-page/eidt-page-bg.png"} /></div>
							{!imageLoaded && <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
								<div class="skype-loader">
									<div class="dot">
										<div class="first"></div>
									</div>
									<div class="dot"></div>
									<div class="dot"></div>
									<div class="dot"></div>
								</div>
								{/* <p>Please wait...</p> */}
							</div>}
						<div className="edit-background-frame" style={{ backgroundImage: `url(${data.image?.backgroundFrame || backgroundFrame})` }} onLoad={handleImageLoad}></div>
						<div className="result-inner" >
						 <div className="joke-result-inner" style={quoteStyle}>
						    <div className={`editor-page edit-page-text ${pouupContent === "message_edit" && 'active'}`} onClick={()=>{inlineHandleClick("message_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
							<div className="joke4-image">
							   <img src={pankajtripathi} id="image-div" className="" onLoad={handleImageLoad} /> 
							</div>
							<h3  className="template-heading"> 
							<span contentEditable={pouupContent === "heading_edit" && true} >
							{title === "Othersa" ? null : showMessage.heading}
							<div className={`editor-page edit-page-heading ${pouupContent === "heading_edit" && 'active'}`} onClick={()=>{inlineHandleClick("heading_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
							</span>
						 </h3>
							<p style={messageStyle} className="template-message" contentEditable={pouupContent === "message_edit" && true} onBlur={handleMessageChange}>{showMessage.message}</p>
				     	 </div>
				 		</div>
						<p className="branding-logo"><img src={branding} alt="branding" />Created with Varnz.ai</p>

					</div>
				}
			</div>
		</>
	);
	return design;
}