import { useEffect } from "react";
import React from 'react';
import { useState } from "react";
import "./Carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { imageTemplate, commonTabs, defaultProp } from "../imageTemplate";
import html2canvas from 'html2canvas';
import axios from "axios";
import { useNavigate } from "react-router-dom"

function Carousel1({ imageResponse, clickedTabs }) {
  const [showLoader, setShowLoader] = useState(false);
  const [showLoader1, setShowLoader1] = useState(-1)
  const templateData = {
    heading: imageResponse?.title,
    message: imageResponse?.message
  }
  // console.log(imageResponse,"kkkkkkkkkkkqwqqkkkk",imageTemplate[`${imageResponse?.category.replaceAll(" ", "_")}`],imageResponse?.category.replaceAll(" ", "_"))

  let combinedArray = { ...imageTemplate[`${imageResponse?.category.replaceAll(" ", "_")}`][`${imageResponse?.type.replaceAll(" ", "_")}`][`${imageResponse?.occasion}`], ...commonTabs };
  let imageTemplates = "";
  if (clickedTabs?.subTabs) {
    try {
      imageTemplates = combinedArray[`${clickedTabs?.tabs}`].imageTemplate;
    } catch (er) {
      imageTemplates = combinedArray[`Love_Cards`].imageTemplate;
    }
  } else if (clickedTabs?.tabs) {
    try {
      imageTemplates = combinedArray[`${clickedTabs?.tabs}`].imageTemplate;
    } catch (err) {
      imageTemplates = combinedArray[`Love_Cards`].imageTemplate;
    }
  } else {
    imageTemplates = combinedArray[`Love_Cards`].imageTemplate;
  }
 


  const generateLink = async (e,index) => {
    // console.log(document.getElementById(`generate-link-${index}`).previousSibling)
    // document.getElementById(`generate-link-${index}`).previousSibling.classList.remove("d-none");
    // document.getElementById(`generate-link-${index}`).classList.add("d-none")
    setShowLoader(true);
    setShowLoader1(index);
    try {
      const elementToCapture = document.getElementById(`generate-link-${index}`).children[0].children[0];
      // const style = document.createElement('style');
      // document.head.appendChild(style);

      // Define the CSS rule for the :before pseudo-element
      // const cssRule = `
      //       .full-overlay:before {
      //         content: '';
      //         position: absolute;
      //         top: 0;
      //         left: 0;
      //         width: 1080px;
      //         height: 1080px;
      //         background: #000c;
      //         opacity: .1;
      //       }
      //     `;
      // Add the CSS rule to the style element
      // style.sheet.insertRule(cssRule, 0);

      // Now you can add the 'full-overlay' class to your element
      // elementToCapture.classList.add('full-overlay');
      // const originalHeight = elementToCapture.offsetHeight;
      // const originalWidth = elementToCapture.offsetWidth;
      // elementToCapture.style.width = "1080px";
      // elementToCapture.style.height = "1080px";
      // const templateHeading = elementToCapture.querySelector('.template-heading');
      // const templateMessage = elementToCapture.querySelector('.template-message');
      // original style
      // const originalHeadingFontSize = templateHeading.style.fontSize;
      // const oriignalMessageFontSize = templateMessage.style.fontSize;
      // templateHeading.style.fontSize = "70px";
      // templateMessage.style.fontSize = "50px";
      // const width = elementToCapture.offsetWidth;
      // const height = elementToCapture.offsetHeight;
      const canvas = await html2canvas(elementToCapture,{ scale: 3, quality: 1, useCORS: true });
      // elementToCapture.style.width = originalWidth + "px";
      // elementToCapture.style.height = originalHeight + "px";
      // templateHeading.style.fontSize = originalHeadingFontSize;
      // templateMessage.style.fontSize = oriignalMessageFontSize;
      setShowLoader(false);
      setShowLoader1(-1);
      const imgDataUrl = canvas.toDataURL('image/png');
      const blob = await fetch(imgDataUrl).then((res) => res.blob());

      const formData = new FormData();
      formData.append('title', imageResponse.title);
      formData.append('message', imageResponse.message);
      formData.append('type', imageResponse.type);
      formData.append('category', imageResponse.category);
      formData.append('occasion', imageResponse.occasion);
      formData.append('tabs', imageResponse.tabs);
      formData.append('image', blob, 'image.png');
      const url = process.env.REACT_APP_SHARE_IMAGE_URL;
      const response = await axios.post(url, formData);
      // Navigate(`/share?image=${response.data.data._id}`);
      const newTab = window.open(`/share?image=${response.data.data._id}`, '_blank');
      if (newTab) {
        newTab.focus(); // Focus on the new tab if it was successfully opened
      }
      return;
    } catch (err) {
      console.log(err);
    }
  }


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="App">
      <div style={{ position: "relative" }}>
        <Carousel responsive={responsive}>
          {imageTemplates.map((data, index) => {
            if (!data.div) {
              data.div = defaultProp.div
            }
            if (!data.heading) {
              data.heading = defaultProp.heading
            }
            if (!data.message) {
              data.message = defaultProp.message
            }
            if (data.isOwnDesign) {
              return (
                <div
                >
                  {
                    index === showLoader1 ? (
                      <div style={{ width: "100%", height: "100%" }} className="please-wait">
                        <div class="skype-loader">
                          <div class="dot">
                            <div class="first"></div>
                          </div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                        </div>
                        <p>Please wait...</p>
                      </div>
                    ) : (
                      <div
                        id={`generate-link-${index}`}
                        onClick={(e) => generateLink(e,index)}
                      >
                        <data.design shareImage={true} data={data} index={index} title={imageResponse.title} message={imageResponse.message} templateData={templateData} />
                      </div>

                    )
                  }
                  {/* <div style={{ width: "100%", height: "100%" }} className="please-wait d-none">
                    <div class="skype-loader">
                      <div class="dot">
                        <div class="first"></div>
                      </div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                    <p>Please wait...</p>
                  </div>
                  <div
                    id={`generate-link-${index}`}
                    onClick={(e) => generateLink(e,index)}
                  >
                    <data.design shareImage={true} data={data} index={index} title={imageResponse.title} message={imageResponse.message} />
                  </div> */}

                </div>
              );
            }

          })}
        </Carousel>
      </div>
    </div>
  );
}

const CarouselComponent = React.memo(Carousel1);

export default CarouselComponent;
