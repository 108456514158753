import React from 'react';
import "./avtar.css";
const AvatarWithLetter = ({ name, imageUrl,AllData }) => {
  const firstLetter = name && name?.charAt(0)?.toUpperCase();
  const color = ["#ff7027","#00AAE9","#00114B"];
  const index = Math.floor(Math.random() * (4 - 0 + 0)) + 0;
  // alert(index)style={{backgroundColor:color[index]}}
  return (
    <div className="avatar-container" >
      <img src={imageUrl}  alt="" className="avatar-image" />
      {/* <div className="avatar-letter" style={{backgroungColor:AllData?.background_color}} >{firstLetter?firstLetter:<i class="fas fa-user"></i>}</div> */}
      <div className="avatar-letter">
      {firstLetter ? firstLetter : <i className="fas fa-user"></i>}
      </div>

    </div>
  );
};

export default AvatarWithLetter;
