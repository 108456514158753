import React from "react";
import Header from "../../../../landingpage/header";
import logo from "../../../../landingpage/image/logo.png";
import temp2one from "../../../../landingpage/image/template2-icon1.png";
import template2img from "../../../../landingpage/image/template2-ico2.png";
import employee from "../../../../landingpage/image/employee.jpg";
import template2frame from "../../../../landingpage/image/template2-frame.png";
import frame2 from "../../../../landingpage/image/frame2.jpg";
import download from "../../../../landingpage/image/download_icon.png";
import { handleDownloadClick } from "../../../../landingpage/designTemplates/commonFunction";
import { useRef, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { savedTemplate, editTemplate, Footer, SocialLink } from "./common";
import save from "../../../../landingpage/image/save.png";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../landingpage/image/edit_icon_white.png";
import branding from "../../../../landingpage/image/refer-frnd.png";




const BirthdayTemplate = ({ promptResponse, resultImage, formData, corporateUserInfo, showTemplateTitle, sendData, designType, category, occasion, prompt, savedData, isSavedTemplate, index, setMasonaryValue, setEditData, dispatch, multiformRequest, showFooterButton, titleColor, responseColor, backgroundImage, onlineImage, isEditPage, isPersoanlInfo, otherColor, titleFontFamily,
    messageFontFamily,
    otherFontFamily,
    fontSize,
    fontStyle,
    textAlignment, allFormData,
    isSavedEdit,
    logo,
    isDefault,
    company_information,
    setShowSocialMedia,
    showUploadImageActive,
    showCompanyInfoBtn ,
    nameDesign="name-d2",
    nameStyle
}) => {

        const panelRef = useRef(null);
        const [widths,setWidth] = useState({
          width:"200px",
          height:"auto"
        })

        useEffect(() => {
            const resizeObserver = new ResizeObserver(entries => {
              for (let entry of entries) {
                console.log('Element resized:', entry.target.style.width);
                // Call your function here
                setWidth({
                  width:entry.target.style.width,
                  height:entry.target.style.height
                })
              }
            });
        
            if (panelRef.current) {
              resizeObserver.observe(panelRef.current);
            }
        
            return () => {
              resizeObserver.disconnect();
            };
          }, []);
    // const templateData = JSON.parse(promptResponse);
    const title_style = {
        font_family: titleFontFamily,
        font_size: fontSize?.titleFontSize,
        font_style: {
            italic: fontStyle?.titleFontStyle.italic,
            bold: fontStyle?.titleFontStyle.bold,
            underline: fontStyle?.titleFontStyle.underline
        },
        text_alignment: textAlignment?.titleTextAlign.textAlign,
        color: titleColor
    }
    const message_style = {
        font_family: messageFontFamily,
        font_size: fontSize?.messageFontSize,
        font_style: {
            italic: fontStyle?.messageFontStyle.italic,
            bold: fontStyle?.messageFontStyle.bold,
            underline: fontStyle?.messageFontStyle.underline
        },
        text_alignment: textAlignment?.messageTextAlign.textAlign,
        color: responseColor
    }
    const other_style = {
        font_family: otherFontFamily,
        font_size: fontSize?.otherFontSize,
        font_style: {
            italic: fontStyle?.otherFontStyle.italic,
            bold: fontStyle?.otherFontStyle.bold,
            underline: fontStyle?.otherFontStyle.underline
        },
        text_alignment: textAlignment?.otherTextAlign.textAlign,
        color: otherColor
    }
    const nameStyles = {
        fontSize: nameStyle?.fontSize?.nameFontSize,
        fontFamily: nameStyle?.nameFontFamily,
        color: nameStyle?.nameColor,
        fontWeight: nameStyle?.fontStyle?.nameFontStyle?.bold?.fontWeight,
        textDecoration: nameStyle?.fontStyle?.nameFontStyle?.underline?.textDecoration,
        fontStyle: nameStyle?.fontStyle?.nameFontStyle?.italic?.fontStyle,
        textAlign: nameStyle?.textAlignment?.nameTextAlign?.textAlign,
        textTransform: "capitalize",
        backgroundColor: (nameDesign === "name-d2" || nameDesign === "name-d3") ? nameStyle?.backgroundColor : "",
        borderColor: (nameDesign === "name-d4") ? nameStyle?.borderColor : "",
    }
    const captureDivRef1 = useRef(null);
    const Navigator = useNavigate();
    const alert = useAlert();
    const [downloadText, setDownloadText] = useState({
        text: "Download",
        disabled: false,
    });
    const [saveImage, setSaveImage] = useState({
        text: "Save",
        disabled: false,
    });
    const [persoanlInfo, setPersonalInfo] = useState(false);
    const [imageHeight, setImageHeight] = useState(true);
    const [imageSize,setImageSize] = useState({
        width:"",
        height:""
    });
    const message = promptResponse;
    let name = "";
    if (isSavedTemplate) {
        name = savedData?.name
    } else if (isSavedEdit) {
        name = allFormData.name
    } else {
        name = formData?.name
    }

    useEffect(() => {
        if (isSavedTemplate) {
            // console.log(savedData?.persoanlInfo)
            setPersonalInfo(savedData?.persoanlInfo);
        }
    }, [])
    useEffect(() => {
        if (company_information === true || company_information === false) {
            setPersonalInfo(company_information);
        }
    }, [company_information])
    // useEffect(() => {
    //     if (company_information === true || company_information === false) {
    //         setShowSocialMedia(persoanlInfo);
    //     }
    // }, [persoanlInfo])
    let background_image = null;
    if (isSavedTemplate) {
        if (savedData?.background_image) {
            background_image = {
                backgroundImage: `url(${savedData?.background_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    } else {
        if (backgroundImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    if (isSavedEdit) {
        if (onlineImage) {
            background_image = {
                backgroundImage: `url(${onlineImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }
        }
    }

    // const imageUrl = isDefault ? resultImage : allFormData?.profile_pic?.includes("storage")
    // ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}`
    // : allFormData?.profile_pic || resultImage && URL.createObjectURL(resultImage) || `${process.env.REACT_APP_URL_LOCAL}/${savedData?.profile_pic}`;

    // useEffect(() => {
    //     if (imageUrl) {
    //         const backgroundImage = new Image();
    //         backgroundImage.src = imageUrl;
    //         backgroundImage.onload = () => {
    //             if (backgroundImage.naturalHeight > backgroundImage.naturalWidth) {
    //                 setImageHeight(true)
    //                 const myDiv = document.getElementById("frame2image");

    //                 // Get the computed height of the div
    //                 const computedHeight = window.getComputedStyle(myDiv).height;
    //                 const computedWidth = window.getComputedStyle(myDiv).width;
    //                 const numericHeight = parseInt(computedHeight, 10);
    //                 const numericWidth = parseInt(computedWidth, 10);
    //                 console.log("Computed Height:", computedHeight,computedWidth);

    //                 // Calculate aspect ratio
    //                 const aspectRatio = backgroundImage.naturalHeight / backgroundImage.naturalWidth;
    //                 // Calculate height
    //                 const height = numericWidth / aspectRatio;
    //                 if(height < numericHeight){
    //                     setImageHeight(false);
    //                 }
    //             } else {
    //                 setImageHeight(false)
    //             }
    //         };
    //     }
    // }, [imageUrl]);

    const design = (
        <>
            <div className="business-templates-item template2" ref={captureDivRef1} style={background_image}>
                <div className="first-temp-head">
                    <img className="temp-logo" src={`${isDefault ? logo : `${process.env.REACT_APP_URL_LOCAL}/${corporateUserInfo.image}`}`} alt="branding" />
                    {persoanlInfo && <a className="template-call" href="#"><i class="fa-solid fa-phone"></i>{corporateUserInfo.phone_number}</a>}
                </div>
                <div className="bday-frame">
                    <div className="template2-frame">
                        <img className="template2frame" src={template2frame} alt="template2frame" />
                        {
                            isSavedTemplate ? (
                                // !backgroundImage ? (
                                // <div className="frame2image div-image" style={{ backgroundImage: `url(${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic})` }}></div>
                                <div className="temp2-img frame2image div-image" id="croped-size-el">
                                    <img className="tamp-img" src={`${process.env.REACT_APP_URL_LOCAL}/${savedData.profile_pic}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : '270px'}` }} />
                                </div>
                                // ) : (
                                //     <div className="frame2image div-image" style={{ backgroundImage: `url(${savedData.profile_pic})` }}></div>
                                // )


                            ) : (
                                !isSavedEdit ? (
                                    // <div className="frame2image div-image" style={{ backgroundImage: `url(${resultImage && URL.createObjectURL(resultImage)})` }}></div>
                                    <div className="temp2-img frame2image div-image" id="croped-size-el">
                                        {
                                            isDefault ? (
                                                <img className="tamp-img" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                            ):(
                                                showUploadImageActive?.cropedImage ? (
                                                    <img className="tamp-img" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                                ):(
                                                    <img className="tamp-img" src={`${resultImage && URL.createObjectURL(resultImage)}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                )
                                                
                                            )
                                        }
                                        
                                    </div>

                                ) : (
                                    // <div className="frame2image div-image" style={{ backgroundImage: `url(${allFormData?.profile_pic.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}`: allFormData?.profile_pic})` }}></div>
                                    <div className="temp2-img frame2image div-image" id="croped-size-el">
                                        {
                                            showUploadImageActive.uploadedImage ? (
                                                <img className="tamp-img" src={`${resultImage && URL.createObjectURL(resultImage)}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                            ):(
                                                showUploadImageActive?.cropedImage ? (
                                                    <img className="tamp-img" src={`${resultImage}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : '100%'}`, height: `${!imageHeight ? '100%' : '100%'}` }} />
                                                ):(
                                                    <img className="tamp-img" src={`${allFormData?.profile_pic.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${allFormData?.profile_pic}` : allFormData?.profile_pic}`} alt="temp3-img" style={{ width: `${imageHeight ? '100%' : 'auto'}`, height: `${!imageHeight ? '100%' : 'auto'}` }} />
                                                )
                                                
                                            )
                                        }
                                        
                                    </div>

                                )
                            )
                        }
                        {/* <div className="frame2image" style={{backgroundImage:`url(${resultImage && URL.createObjectURL(resultImage)})`}}></div> */}
                        {/* <img className="frame2image" src={resultImage && URL.createObjectURL(resultImage)} alt="frame2" /> */}
                    </div>
                    <div className="template2-frame-heading">
                    <h4 class={`ribbon temp1-name name-d1 name-dsgn ${nameDesign === "name-d1" && 'active'}`}><span class="ribbon-content" style={nameStyles}>{name}</span></h4>
                        <h4 class={`name-d2 name-dsgn ${nameDesign === "name-d2" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d3 name-dsgn ${nameDesign === "name-d3" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d4 name-dsgn ${nameDesign === "name-d4" && 'active'}`} style={nameStyles}>{name}</h4>
                        <h4 class={`name-d5 name-dsgn ${nameDesign === "name-d5" && 'active'}`} style={nameStyles}>{name}</h4>
                    </div>
                </div>
                <div className="first-temp-footer">
                    <div className="secnd-ftr2">
                        <img className="template2-ico2" src={template2img} alt=" template2-ico2" />
                        <h2 id="template-heading" style={{ color: `${titleColor}`, fontFamily: `${titleFontFamily}`, fontSize: `${fontSize?.titleFontSize}`, fontWeight: `${fontStyle?.titleFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.titleFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.titleFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.titleTextAlign.textAlign}` }}>{showTemplateTitle}</h2>
                        <p id="template-message" style={{ whiteSpace: 'pre-wrap', color: `${responseColor}`, fontFamily: `${messageFontFamily}`, fontSize: `${fontSize?.messageFontSize}`, fontWeight: `${fontStyle?.messageFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.messageFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.messageFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.messageTextAlign.textAlign}` }}>{message}</p>
                    </div>
                    <img className="temp2one" src={temp2one} alt="temp2one" />
                </div>
                <div className="first-temp-footer ftr-links">
                    {
                        persoanlInfo &&
                        <ul className="brdr-ftr-design">
                            {/* {
                                corporateUserInfo.company_address && <li style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="address-icon"></i>
                                    <p style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }}>{corporateUserInfo.company_address}</p>
                                </li>
                            } */}
                            {
                                corporateUserInfo?.website_url && <li style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="link-icon"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.website_url}`}>{corporateUserInfo.website_url}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.facebook_link && <li className="social-temp-link facebook-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-facebook-f"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.facebook_link}`}>{corporateUserInfo.facebook_link}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.instagram_link && <li className="social-temp-link instagram-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-instagram"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.instagram_link}`}>{corporateUserInfo.instagram_link}</a>
                                </li>
                            }
                            {
                                corporateUserInfo?.twitter_link && <li className="social-temp-link twitter-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                                    <i class="fa-brands fa-twitter"></i>
                                    <a style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }} target="_blank" href={`${corporateUserInfo.twitter_link}`}>{corporateUserInfo?.twitter_link}</a>
                                </li>
                            }
                        </ul>
                    }
                </div>
                {
                    !showFooterButton &&
                    <Footer
                        index={index}
                        persoanlInfo={persoanlInfo}
                        setPersonalInfo={setPersonalInfo}
                        setMasonaryValue={setMasonaryValue}
                        downloadText={downloadText}
                        handleDownloadClick={handleDownloadClick}
                        setDownloadText={setDownloadText}
                        captureDivRef1={captureDivRef1}
                        showTemplateTitle={showTemplateTitle}
                        sendData={sendData}
                        alert={alert}
                        savedTemplate={savedTemplate}
                        isSavedTemplate={isSavedTemplate}
                        saveImage={saveImage}
                        formData={formData}
                        designType={designType}
                        category={category}
                        occasion={occasion}
                        promptResponse={promptResponse}
                        corporateUserInfo={corporateUserInfo}
                        prompt={prompt}
                        setSaveImage={setSaveImage}
                        backgroundImage={backgroundImage}
                        onlineImage={onlineImage}
                        title_style={title_style}
                        message_style={message_style}
                        other_style={other_style}
                        isSavedEdit={isSavedEdit}
                        allFormData={allFormData}
                        save={save}
                        isEditPage={isEditPage}
                        editTemplate={editTemplate}
                        Navigator={Navigator}
                        editIcon={editIcon}
                        download={download}
                        setEditData={setEditData}
                        dispatch={dispatch}
                        showCompanyInfoBtn={showCompanyInfoBtn}
                    />
                }
                {persoanlInfo &&
                    <div className="template3-footer new-btm-ftr">
                        <div className="social-temp-link address-" style={{ justifyContent: `${textAlignment?.otherTextAlign.textAlign}` }}>
                            <i>
                                <svg class="address-icon" fill="fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="15px" height="15px" viewBox="0 0 395.71 395.71" xmlSpace="preserve">
                                    <g>
                                        <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.951-6.738c5.305-7.211,129.896-177.501,129.896-250.388
                                            C335.168,61.609,273.569,0,197.849,0z M197.849,199.571c-27.633,0-50.143-22.51-50.143-50.143s22.51-50.143,50.143-50.143
                                            s50.143,22.51,50.143,50.143S225.482,199.571,197.849,199.571z" />
                                    </g>
                                </svg>
                            </i>
                            <p style={{ color: `${otherColor}`, fontFamily: `${otherFontFamily}`, fontSize: `${fontSize?.otherFontSize}`, fontWeight: `${fontStyle?.otherFontStyle.bold.fontWeight}`, fontStyle: `${fontStyle?.otherFontStyle.italic.fontStyle}`, textDecoration: `${fontStyle?.otherFontStyle.underline.textDecoration}`, textAlign: `${textAlignment?.otherTextAlign.textAlign}` }}>{corporateUserInfo.company_address}</p>
                        </div>
                    </div>
                }
                <p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
            </div>
        </>
    );
    return design
}

export default React.memo(BirthdayTemplate);