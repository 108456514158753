const SEND_HEADERS = process.env.REACT_APP_SEND_SEND_HEADERS;

export const unauthenticateConfig = () => {
    const config = {
      headers: {
        "x-auth-token": SEND_HEADERS,
      },
    };
    return config;
  };

// All Header 
  export const authenticateConfig = (TokenGet) => {
    const config = {
      headers: {
        "x-auth-token": TokenGet,
      },
    };
    return config;
  };


  // image upload header?
  export const HeaderAuthImageUpload = (TokenGet) => {
    const config = {
      headers: {
        "x-auth-token": TokenGet,
        "Content-Type": "multipart/form-data",
      },
    };
    return config;
  };



// signup URL
  export const SendURL = () => {
    return process.env.REACT_APP_SEND_OTP_URL;
  };