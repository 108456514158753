// import SignupReducer from "./cmp/Signup/Signup.reducer";
import ProfileReducer,{SocialLogindata} from "./Main/Components/Invitation/Profile.reducer";
import ChatComponentReducer from "./Main/Components/chat/chatComponent.reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import dataReducer from "./Main/Components/common/ReduxApply/dataReducer";
import MultiformReducer from "./Main/Components/multiStepForm/multiform.reducer";
const middlewares = applyMiddleware(thunk,logger);
const root = combineReducers({
  // SignupReducer,
  ProfileReducer,
  MultiformReducer,
  ChatComponentReducer,
  data: dataReducer,
  SocialLogindata:SocialLogindata
});

const storage = createStore(root,{},middlewares);
export default storage;
