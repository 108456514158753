import { useAsync } from "react-async";

import {
  Outlet,
  Navigate
} from "react-router";

import Cookies from "universal-cookie";
import axios from "axios";
// axios.defaults.baseURL = process.env.REACT_APP_URL_LOCAL;

const verifyToken = async ({token})=>{
  try {
    // const response = await axios({
    //   method: "get",
    //   url: "/verify-token"
    // },{

    // });
    // console.log(token,"Token VerifyFunc")
    const url = `${process.env.REACT_APP_URL_LOCAL}/verify-token`;
    const headers = {
      "x-auth-token" : token
    }
    const response = await axios.get(url,{
      headers
    });
    console.log(response,"TokenResponse",token)
    return response;
  }
  catch(err)
  {
    throw new Error(err);
  }
}

const AuthGuard = ()=>{
  const cookie = new Cookies();
  const token = cookie.get("authToken");
  // console.log("TEEEEEEEEEEEEEEEEEEEEEEEE",token)
  const { data, error } = useAsync({
    promiseFn: verifyToken,
    token: token
  });
  // console.log(data,"KKKKKKKKKKKKKKKKKKK")
  if(data)
  {
    // let user = JSON.stringify(data.data.data.data);
    // sessionStorage.setItem("user",user);
    return <Outlet />;
  }
  if(error)
  {
    return <Navigate to="/login" />
  }
  return null;
}

export default AuthGuard;
