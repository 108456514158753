// dataReducer.js
import {
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE
  } from './actionTypes';
  // dataReducer.js
  const initialState = {
    dataOn: null,
    subscriptionDetails: null,
    loading: false,
    error: null
  };
  
  const dataReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATA_REQUEST:
        return {
          ...state,
          loading: true
        };
      case FETCH_DATA_SUCCESS:
        return {
          ...state,
          dataOn: action.payload.dataOn,
          subscriptionDetails: action.payload.subscriptionDetails,
          loading: false,
          error: null
        };
      case FETCH_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default dataReducer;
  