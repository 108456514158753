import { useEffect, useState } from "react";
import useUserData from "../imagination/useUserData";
import varnzgreeting from "../../Components/imagination/Images/varnz-prsnl1.png";
import varnzgreeting1 from "../../Components/imagination/Images/varnz-prsnl2.png";
import varnzgreeting2 from "../../Components/imagination/Images/varnz-prsnl3.png";
import varnzgreeting3 from "../../Components/imagination/Images/Varnz--greeting3.png";
import personalwelcome1 from "../../Components/imagination/Images/personal-welcome1.png";


import { getAuth } from "../../../components/services/auth";
import Imaginationheader from "./imaginationHeader";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryJson from "../../../json/country.json";
import axios from "axios";
import AvatarWithLetter from "../../../commonComponents/avtar";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import ReactCrop from "react-image-crop";
import { Alert } from "react-bootstrap";
import { useAlert } from 'react-alert';

import { useDispatch } from "react-redux";
import { profileRequest } from "../Invitation/Profile.action";

const AiEdit = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const userData = JSON.parse(localStorage.getItem("guitar_user"));
  const uid = userData.uid;
  const token = localStorage.getItem("Exapirtoken");

  const EDIT_USER_DETAILS = process.env.REACT_APP_SEND_EDIT_USER_DETAILS;
  const URL_LOCAL = process.env.REACT_APP_URL_LOCAL;

  const [Bgcolor, setBgcolor] = useState();
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [result, setResult] = useState(null);
  const [src, selectFile] = useState(null);
  const [key, setKey] = useState(0);
  const [image2, setImage2] = useState(null);
  const [openModel, setOpenModel] = useState(true);
  const defaultCrop = {
    unit: "%",
    width: 100,
    height: 100,
    aspect: 1, // You can adjust this aspect ratio as needed
  };
  const [crop, setCrop] = useState(defaultCrop);
  const [imageError, setImageError] = useState({
    showError: false,
    text: "",
  });

  const handleClose1 = () => {
    setOpenModel(false);
  };

  function getCroppedImg() {
    const canvas = document.createElement("canvas");
    const scaleX = image2.naturalWidth / image2.width;
    const scaleY = image2.naturalHeight / image2.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio * (scaleX / 4);
    canvas.height = crop.height * pixelRatio * (scaleY / 4);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image2,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      Math.round(crop.width * (scaleX / 4)),
      Math.round(crop.height * (scaleY / 4))
    );
    const base64Image = canvas.toDataURL("image/png", 1);
    setResult(base64Image);
    setOpenModel(false);
  }

  const [disabled, setDisabled] = useState(false);
  const [imageData, setImageData] = useState(""); // For image upload, if needed
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [personality, setPersonality] = useState(""); // State for personality
  const [personalityError, setPersonalityError] = useState("");


  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    gender: "",
    date_of_birth: "",
    personality: "",
    country: "",
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
    image_path: "",
  });


  const [socialUrl, setSocialUrl] = useState({
    facebook_url: "",
    twitter_url: "",
    linkedin_url: "",
    instagram_url: ""
  });

  const [socialUrlError, setSocialUrlError] = useState({
    facebook_url: "",
    twitter_url: "",
    linkedin_url: "",
    instagram_url: ""
  });

  const handleSocialUrl = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    setSocialUrl((oldData) => ({
      ...oldData,
      [key]: value
    }));
  };

  const validateSocialUrl = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Basic URL validation (you can expand this as needed)
    const urlPattern = /^(https?:\/\/)?(www\.)?(facebook\.com|instagram\.com|linkedin\.com|twitter\.com)\/[A-Za-z0-9_-]+$/;

    if (value && !urlPattern.test(value)) {
      error = "Please enter a valid URL.";
    }

    setSocialUrlError((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  useEffect(() => {
    // Fetch user data and populate the socialUrl state
    const fetchUserData = async () => {
      // Assume this is your API call to get user data
      const userData = {
        facebook_url: "https://facebook.com/example",
        instagram_url: "https://instagram.com/example",
        twitter_url: "https://twitter.com/example",
        linkedin_url: "https://linkedin.com/in/example"
      };
      setSocialUrl(userData);
    };

    fetchUserData();
  }, []);

  const [gender, setGender] = useState(userDetails ? userDetails.gender : "");
  const [genderError, setGenderError] = useState("");

  const getUserData = async () => {
    try {
      const response = await fetch(
        `https://varnz-backend.webgarh.net/api/users/getUser/${uid}`,
        {
          method: "GET",
          headers: {
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      if (data.status === 1) {
        setUserDetails(data.data);
        setPersonality(data.data.personality || "🎁 Ambivert");
        setCountry(data.data.country || "");
        setGender(data.data.gender || ""); // Set default gender from fetched data
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePersonalityChangeInput = (selectedOption) => {
    setPersonality(selectedOption.value);
    setPersonalityError(""); // Reset error if personality is selected
  };

  const handleCountryChangeInput = (selectedOption) => {
    setCountry(selectedOption.value);
    setCountryError(""); // Reset error if country is selected
  };

  const handleGenderChangeInput = (e) => {
    setGender(e.target.value);
    setGenderError(""); // Reset any previous error
  };

  const imageChange = (e) => {
    // if (e.target.files && e.target.files.length > 0) {
    //   const selectedFile = e.target.files[0];
    //   setSelectedImage(selectedFile);
    //   setImageName(selectedFile.name);
    // }
    e.preventDefault();
    setImageError({
      showError: false,
      text: "Please select a grether than 100KB.",
    });
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    // console.log(files[0].size,"llllllllll")
    if (files[0].size > 5242880) {
      // return alert("Please select a less than 5MB.");
      setImageError({
        showError: true,
        text: "Please select image less than 5MB.",
      });
      setTimeout(() => {
        setImageError({
          showError: false,
          text: "Please select a less than 5MB.",
        });
      }, 3000);
      return;
    }
    if (files[0].size < 102400) {
      // return alert("Please select a grether than 100KB.");
      setImageError({
        showError: true,
        text: "Please select image grether than 100KB.",
      });
      setTimeout(() => {
        setImageError({
          showError: false,
          text: "Please select a less than 5MB.",
        });
      }, 3000);
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const uploadedFile = {
        data: reader.result,
        name: file.name,
        size: file.size,
        type: file.type,
      };
      // console.log(uploadedFile,"ssissi")
      setKey((prevKey) => prevKey + 1);
      selectFile(uploadedFile.data);
      setOpenModel(true);
      setCrop(defaultCrop);
      // getUploadedFile(uploadedFile);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let imageData = "";
    if (result) {
      const base64ImageWithoutPrefix = result?.split(",")[1];
      const decodedImage = atob(base64ImageWithoutPrefix);
      imageData = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        imageData[i] = decodedImage.charCodeAt(i);
      }
    }

    // Construct data to send
    const datasend = {
      name: userDetails.name,
      gender: gender,
      dob: userDetails.date_of_birth,
      type: "profile",
      uid: uid,
      personality: personality,
      country: country,
      image: imageData,
      facebook_url: socialUrl.facebook_url,
      twitter_url: socialUrl.twitter_url,
      linkedin_url: socialUrl.linkedin_url,
      instagram_url: socialUrl.instagram_url,
    };

    // Set the request config
    const config = {
      headers: {
        "x-auth-token": getAuth()?.dynamicUserToken,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      setDisabled(true); // Disable button during submission
      const response = await axios.post(EDIT_USER_DETAILS, datasend, config);

      if (response.data.status === 1) {
        alert.success("Profile Updated Successfully!", {
          position: "top right",
        });
        dispatch(profileRequest(datasend)); // Dispatch the profile update action
      } else {
        alert.error("Profile update failed. Please try again."); // Handle other status codes if necessary
      }

      // Optional: reload the page or take any other action if needed
      setTimeout(() => {
        setDisabled(false); // Re-enable button after 2 seconds
      }, 2000);
    } catch (error) {
      console.error(error);
      alert.error(error.message); // Show error alert
      setDisabled(false); // Re-enable button on error
    }
  };

  return (
    <div className={`multiform-main business-page`}>
      <Imaginationheader />
      <div className="business-choice personal-ai-edit-page">
        <div className="business-choice-inner">
          <div className="business-choose-left">
            <img
              className="prsnl-img1"
              src={varnzgreeting}
              alt="celebration-people"
            />
            <img
              className="prsnl-img2"
              src={varnzgreeting1}
              alt="celebration-people"
            />
            <img
              className="prsnl-img3"
              src={varnzgreeting2}
              alt="celebration-people"
            />
            <img
              className="prsnl-img4"
              src={varnzgreeting3}
              alt="celebration-people"
            />
            <div className="business-choose-left-text">
              <h2>Update Your Profile</h2>
              <p>
                Keep your information up to date. Edit your profile data below
                to ensure your account reflects the most current details.
              </p>
              <img src={personalwelcome1} alt="celebration-people"></img>
            </div>
          </div>
          <div className="business--choose-form">
            <div className="business-choose-right-form">
              <div className="business-choose-right-form-inner">
                <div className="business-main">
                  <div className="business-form">
                    <form onSubmit={handleSubmit}>
                      <div className="personal-info">
                        <h2>Personal Information</h2>
                        <p className="main-busness-text">
                          We recommend you fill this information to get started
                          with a VARNZ AI personal account.
                        </p>

                        <div className="uploadimg_otr company-logo-selection">
                          <div className="uploadimg_area">
                            <div className="img_area_iut">
                              {result ? (
                                <img src={result} alt="Uploaded Thumbnail" />
                              ) : userDetails.image_path ? (
                                <img
                                  src={`${URL_LOCAL}/${userDetails.image_path}`} 
                                  alt="Uploaded"
                                />
                              ) : (
                                <AvatarWithLetter
                                  name={userDetails.name}
                                  imageUrl="path/to/ss.jpg" // Default image path
                                  AllData={Bgcolor}
                                />
                              )}
                            </div>
                            <div className="show_img">
                              <div className="brosw">
                                <input
                                  accept="image/*"
                                  type="file"
                                  id="profile"
                                  key={key}
                                  onChange={imageChange}
                                />
                                <label htmlFor="profile">
                                  <div className="brng">
                                    <i className="fas fa-pen"></i>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                          {imageError.showError && (
                            <Alert severity="error">{imageError.text}</Alert>
                          )}
                        </div>

                        <div className="info-form">
                          {src && (
                            <div>
                              <Dialog
                                open={openModel}
                                onClose={handleClose1}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                PaperProps={{
                                  style: {
                                    maxWidth: "400px",
                                    width: "100%",
                                    maxHeight: "500px",
                                  },
                                }}
                              >
                                <DialogTitle
                                  id="alert-dialog-title"
                                  color={"black"}
                                >
                                  <Typography variant="h5" align="center">
                                    Adjust Image
                                  </Typography>
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText
                                    id="alert-dialog-description"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        maxHeight: "300px",
                                      }}
                                    >
                                      <ReactCrop
                                        src={src}
                                        onImageLoaded={setImage2}
                                        crop={crop}
                                        onChange={setCrop}
                                        circularCrop={true}
                                        ruleOfThirds
                                      />
                                    </div>
                                  </DialogContentText>
                                  <DialogContentText
                                    id="alert-dialog-description"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      justifyItems: "center",
                                    }}
                                  >
                                    <button
                                      className="btn  mt-3 me-3"
                                      onClick={getCroppedImg}
                                      style={{
                                        background: "#ff7027",
                                        color: "white",
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn  mt-3 "
                                      onClick={handleClose1}
                                      style={{
                                        background: "#aeb4b7",
                                        color: "white",
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </DialogContentText>
                                </DialogContent>
                              </Dialog>
                            </div>
                          )}
                          <li>
                            <label>Name:</label>
                            <input
                              type="text"
                              name="name"
                              value={userDetails.name}
                              onChange={handleInputChange}
                            />
                          </li>

                          <li>
                            <label>Email:</label>
                            <input
                              type="email"
                              name="email"
                              value={userDetails.email}
                              onChange={handleInputChange}
                            />
                          </li>

                          <li>
                            <label>Date of Birth:</label>
                            <input
                              type="date"
                              name="date_of_birth"
                              value={
                                userDetails.date_of_birth.split("T")[0] || ""
                              }
                              onChange={handleInputChange}
                            />
                          </li>

                          <li>
                            <label>Personality</label>
                            <Select
                              className="form-select"
                              name="form-field-name"
                              isSearchable={false}
                              options={[
                                { value: "🎁 Ambivert", label: "🎁 Ambivert" },
                                { value: "😄 Optimist", label: "😄 Optimist" },
                                {
                                  value: "⚖️ Open-minded",
                                  label: "⚖️ Open-minded",
                                },
                                {
                                  value: "🙋 Perfectionist",
                                  label: "🙋 Perfectionist",
                                },
                                {
                                  value: "🤧 Empathetic",
                                  label: "🤧 Empathetic",
                                },
                                { value: "🌟 Spiritual", label: "🌟 Spiritual" },
                                {
                                  value: "👍 Independent",
                                  label: "👍 Independent",
                                },
                                {
                                  value: "💭 Analytical",
                                  label: "💭 Analytical",
                                },
                                {
                                  value: "😍 Adventurous",
                                  label: "😍 Adventurous",
                                },
                                {
                                  value: "✨ Fun-loving",
                                  label: "✨ Fun-loving",
                                },
                              ]}
                              value={{ value: personality, label: personality }}
                              menuPlacement="auto"
                              menuPosition={{ fixed: "bottom" }}
                              onChange={handlePersonalityChangeInput}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "none",
                                  boxShadow: "none",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#FF7027"
                                    : "#fff",
                                  color: state.isSelected ? "#fff" : "#333",
                                  ":hover": {
                                    backgroundColor: "#FF7027",
                                    color: "#fff",
                                  },
                                }),
                              }}
                            />
                            {personalityError && (
                              <div className="errormessage">
                                {personalityError}
                              </div>
                            )}
                          </li>

                          <li>
                            <label>Country</label>
                            <Select
                              className="form-select"
                              name="country"
                              isSearchable={true}
                              options={Object.keys(countryJson.country).map(
                                (key) => ({
                                  value: `${countryJson.country[key].flag} ${countryJson.country[key].name}`,
                                  label: `${countryJson.country[key].flag} ${countryJson.country[key].name}`,
                                })
                              )}
                              value={{ value: country, label: country }}
                              menuPlacement="auto"
                              menuPosition={{ fixed: "bottom" }}
                              onChange={handleCountryChangeInput}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "none",
                                  boxShadow: "none",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#FF7027"
                                    : "#fff",
                                  color: state.isSelected ? "#fff" : "#333",
                                  ":hover": {
                                    backgroundColor: "#FF7027",
                                    color: "#fff",
                                  },
                                }),
                              }}
                            />
                            {countryError && (
                              <div className="errormessage">{countryError}</div>
                            )}
                          </li>

                          <li className="gender">
                            {/* <p>Gender</p> */}
                            <div className="form-group border-0 ps-0">
                              <div className="check_genmfr mb-0">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={gender === "male"}
                                    onChange={handleGenderChangeInput}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="male"
                                  >
                                    Male
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={gender === "female"}
                                    onChange={handleGenderChangeInput}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="female"
                                  >
                                    Female
                                  </label>
                                </div>
                              </div>
                            </div>
                            {genderError && (
                              <div className="errormessage">{genderError}</div>
                            )}
                          </li>
                        </div>
                      </div>

                      <div className="personal-info">
                        <h2>Social Links</h2>
                        <p class="main-busness-text">We recommend you fill this information to get started with a VARNZ AI personal account.</p>
                        <ul className="info-form social-links-otr">
                          <li>
                            <label>Facebook</label>
                            <div className="email_plan_area">
                              <div className="form-group">
                                <div className="emk social-links">
                                  <span class="input-group-text"><i class="fa-brands fa-facebook-f"></i></span>
                                  <input
                                    type="text"
                                    id="facebook_url"
                                    name="facebook_url"
                                    aria-label="facebook_url"
                                    className="form-control"
                                    value={socialUrl.facebook_url}
                                    onChange={handleSocialUrl}
                                    onBlur={validateSocialUrl}
                                  />
                                </div>
                              </div>
                            </div>
                            {socialUrlError.facebook_url && <div className="errormessage">{socialUrlError.facebook_url}</div>}
                          </li>

                          <li>
                            <label>Instagram</label>
                            <div className="email_plan_area">
                              <div className="form-group">
                                <div className="emk social-links">
                                  <span class="input-group-text"><i class="fa-brands fa-instagram"></i></span>
                                  <input
                                    type="text"
                                    id="instagram_url"
                                    name="instagram_url"
                                    aria-label="instagram_url"
                                    className="form-control"
                                    value={socialUrl.instagram_url}
                                    onChange={handleSocialUrl}
                                    onBlur={validateSocialUrl}
                                  />
                                </div>
                              </div>
                            </div>
                            {socialUrlError.instagram_url && <div className="errormessage">{socialUrlError.instagram_url}</div>}
                          </li>

                          <li>
                            <label>LinkedIn</label>
                            <div className="email_plan_area">
                              <div className="form-group">
                                <div className="emk social-links">
                                  <span class="input-group-text"><i class="fa-brands fa-linkedin-in"></i></span>
                                  <input
                                    type="text"
                                    id="linkedin_url"
                                    name="linkedin_url"
                                    aria-label="linkedin_url"
                                    className="form-control"
                                    value={socialUrl.linkedin_url}
                                    onChange={handleSocialUrl}
                                    onBlur={validateSocialUrl}
                                  />
                                </div>
                              </div>
                            </div>
                            {socialUrlError.linkedin_url && <div className="errormessage">{socialUrlError.linkedin_url}</div>}
                          </li>

                          <li>
                            <label>Twitter </label>
                            <div className="email_plan_area">
                              <div className="form-group">
                                <div className="emk social-links">
                                  <span class="input-group-text"><i class="fa-brands fa-x-twitter"></i></span>
                                  <input
                                    type="text"
                                    id="twitter_url"
                                    name="twitter_url"
                                    placeholder="Enter your Twitter URL"
                                    className="form-control"
                                    value={socialUrl.twitter_url}
                                    onChange={handleSocialUrl}
                                    onBlur={validateSocialUrl}
                                  />
                                </div>
                              </div>
                            </div>
                            {socialUrlError.twitter_url && <div className="errormessage">{socialUrlError.twitter_url}</div>}
                          </li>
                        </ul>
                      </div>

                      <div className="personal-info-button">
                        <button className="blue-btn cstm-btn" type="submit">
                          <i className="fal fa-save"></i> Save
                        </button>
                      </div>
                      {error && <p className="error">{error}</p>}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiEdit;
