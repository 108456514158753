import { use } from "i18next";
import {
    MULTIFORM_REQUEST,
    MULTIFORM_SUCCESS,
    MULTIFORM_FAILED,
  } from "./multiform.state";
  
  const multiformRequest = (user)=>{
    
    return async (dispatch)=>{
      try {
        dispatch({
          type: "MULTIFORM_REQUEST",
          payload: []
        });
        // const response = await axios({
        //   method: "post",
        //   url: "/login",
        //   data: user
        // });
        dispatch({
          type: "MULTIFORM_SUCCESS",
          payload: user
        });
      }
      catch(err)
      {

        console.log("EROOOOOOOOOOOOOOOOOOOOOOOO",user)
        // if(err.response.status === 404)
        // {
          dispatch({
            type: "MULTIFORM_FAILED"
          });
        // }
        // else {
        //   dispatch({
        //     type: INCORRECT_PASSWORD
        //   });
        // }
      }
    }
  }

  export {
    multiformRequest
  }