import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,SOCIAL_LOGIN_SUCESS,SOCIAL_LOGIN_SUCESS_ERROR
} from "./Profile.state";

const Model = {
  isLoader: false,
  error: null,
  data: null
}

const ProfileReducer = (state=Model,action)=>{
  switch(action.type)
  {
    case PROFILE_REQUEST : return {
      ...state,
      isLoading: true,
      error: null,
      data: null
    }

    case PROFILE_SUCCESS : return {
      ...state,
      isLoading: false,
      data: action.payload,
      error: null
    }

    case PROFILE_ERROR : return {
      ...state,
      isLoading: false,
      error: action.error,
      data: null
    }

    default : return state;

  }
}

export default ProfileReducer;




export const SocialLogindata = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_LOGIN_SUCESS:
      // Assuming you want to store social login data in the 'loginData' property
      return {
        ...state,
        loginData: action.payload, // Update the state with the social login data
      };
    case SOCIAL_LOGIN_SUCESS_ERROR:
      // Assuming you want to store the error in the 'error' property
      return {
        ...state,
        error: action.error, // Update the state with the error data
      }; 
      case "LOGOUT_SUCCESS":
        // Reset the 'loginData' state to an empty object on successful logout
        return {
          ...state,
          loginData: {},
        };
    default:
      return state;
  }
};
