import {
  CHAT_REQUEST,
  CHAT_SUCCESS,
  CHAT_ERROR
} from "./chatComponent.state";

const Model = {
  isLoader: false,
  error: null,
  data: null
}

const ChatComponentReducer = (state=Model,action)=>{
  switch(action.type)
  {
    case CHAT_REQUEST : return {
      ...state,
      isLoading: true,
      error: null,
      data: null
    }

    case CHAT_SUCCESS : return {
      ...state,
      isLoading: false,
      data: action.payload,
      error: null
    }

    case CHAT_ERROR : return {
      ...state,
      isLoading: false,
      error: action.error,
      data: null
    }

    default : return state;

  }
}

export default ChatComponentReducer;
